import React, { useState, useRef} from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

import ImagePin from "./assets/images/pin.png";
import PlaceholderImage from './assets/images/Icono-punto0.png'
import Arrow from './assets/images/arrow.png'

const MapContainer = ({ google }) => {
  const mapRef = useRef(null);
  const [comunaFilter, setComunaFilter] = useState("");

  const [locations] = useState([
    {
      comuna: "ARICA",
      region: "Arica",
      name: "PL SODIMAC ARICA",
      addres: "Avenida Santa María 2985",
      image: PlaceholderImage,
      lat: -18.45977281229116,
      lng: -70.29616137345722,
    },
    {
      comuna: "ARICA",
      region: "Arica",
      name: "Instituto Teletón Arica",
      addres: "Diego Portales 2471",
      image: PlaceholderImage,
      lat: -18.485014056859665,
      lng: -70.29089801877517,
    },
    {
      comuna: "ARICA",
      region: "Arica",
      name: "Enlace Norte",
      addres: "Curalí 848",
      image: PlaceholderImage,
      lat: -18.43944979064183,
      lng: -70.2923099286457,
    },
    {
      comuna: "ARICA",
      region: "Arica",
      name: "Estadio Carlos Dittborn",
      addres: "18 de Septiembre 2000",
      image: PlaceholderImage,
      lat: -18.488113572443904,
      lng: -70.299225512054,
    },
    {
      comuna: "Putre",
      region: "Arica",
      name: "Plaza de Putre",
      addres: "Calle Latorre, casi al llegar a esquina con calle Carrera",
      image: PlaceholderImage,
      lat: -18.196175,
      lng: -69.559474,
    },
    {
      comuna: "IQUIQUE",
      region: "Tarapacá",
      name: "Instituto Teletón Iquique",
      addres: "J.J. Pérez 999, Iquique",
      image: PlaceholderImage,
      lat: -20.221955510334027,
      lng: -70.14602303068392,
    },
    {
      comuna: "Huara",
      region: "Tarapacá",
      name: "Ilustre Municipalidad de Huara",
      addres: "Vicuña Mackenna S/N - Huara ( a un costado de la municipalidad)",
      image: PlaceholderImage,
      lat: -19.994807721201923,
      lng: -69.77155710383508,
    },
    {
      comuna: "Alto Hospicio",
      region: "Tarapacá",
      name: "Municipalidad",
      addres: "Ramon Perez Opazo 3125 ",
      image: PlaceholderImage,
      lat: -20.268644243006865,
      lng: -70.10182206666636,
    },
    {
      comuna: "Alto Hospicio",
      region: "Tarapacá",
      name: "Plaza Condores",
      addres: "-",
      image: PlaceholderImage,
      lat: -20.269826090654902,
      lng: -70.10989008048469,
    },
    {
      comuna: "Pica",
      region: "Tarapacá",
      name: "Punto Localidad de Matilla",
      addres: "Plaza de Matilla",
      image: PlaceholderImage,
      lat: -20.513805656172007,
      lng: -69.36175252336285,
    },
    {
      comuna: "Pica",
      region: "Tarapacá",
      name: "Punto Localidad de Pica",
      addres: "Plaza de Pica",
      image: PlaceholderImage,
      lat: -20.49096988149695,
      lng: -69.32904849020485,
    },
    {
      comuna: "CALAMA",
      region: "Antofagasta",
      name: "PL CALAMA REM",
      addres: "Avenida Granaderos 2548",
      image: PlaceholderImage,
      lat: -22.454757564174773,
      lng: -68.92935233681095,
    },
    {
      comuna: "CALAMA",
      region: "Antofagasta",
      name: "PL SODIMAC CALAMA",
      addres: "Avenida Balmaceda 3398",
      image: PlaceholderImage,
      lat: -22.447519198938405,
      lng: -68.91984505805931,
    },
    {
      comuna: "ANTOFAGASTA",
      region: "Antofagasta",
      name: "Instituto Teletón Antofagasta",
      addres: "Oficina Francisco Puelma 238",
      image: PlaceholderImage,
      lat: -23.551831546883452,
      lng: -70.39401901876178,
    },
    {
      comuna: "CALAMA ",
      region: "Antofagasta",
      name: "Instituto Teletón Calama",
      addres: "Teniente Merino 3551",
      image: PlaceholderImage,
      lat: -22.443983544961455,
      lng: -68.90199423068391,
    },
    {
      comuna: "Antofagasta",
      region: "Antofagasta",
      name: "Parque Juan López",
      addres: "Avenida Pedro Aguirre Cerda 10571",
      image: PlaceholderImage,
      lat: -23.569752,
      lng: -70.389152,
    },
    {
      comuna: "Calama",
      region: "Antofagasta",
      name: "Parque Manuel Rodríguez",
      addres: "Vicuña Mackenna 1971",
      image: PlaceholderImage,
      lat: -22.463391,
      lng: -68.925438,
    },
    {
      comuna: "COPIAPO",
      region: "Atacama",
      name: "PL SODIMAC COPIAPO",
      addres: "Panamericana Norte 140, Copiapó, Región de Atacama",
      image: PlaceholderImage,
      lat: -27.37342707022384,
      lng: -70.33552193098923,
    },
    {
      comuna: "VALLENAR",
      region: "Atacama",
      name: "PL SODIMAC VALLENAR",
      addres: "Avenida Huasco 175",
      image: PlaceholderImage,
      lat: -28.57499999991174,
      lng: -70.78193097618671,
    },
    {
      comuna: "COPIAPO",
      region: "Atacama",
      name: "Instituto Teletón Atacama",
      addres: "La Cruz 760, Población La Pradera, Copiapó",
      image: PlaceholderImage,
      lat: -27.349363862671076,
      lng: -70.34924798465804,
    },
    {
      comuna: "Huasco",
      region: "Atacama",
      name: "Centro de Operaciones ",
      addres: "SOTOMAYOR 568 ",
      image: PlaceholderImage,
      lat: -28.465732861096637,
      lng: -71.21245373778116,
    },
    {
      comuna: "Alto del Carmen",
      region: "Atacama",
      name: "Liceo Alto del Carmen",
      addres: "La Huerta s/n",
      image: PlaceholderImage,
      lat: -28.77703565425937,
      lng: -70.47271450273418,
    },
    {
      comuna: "Alto del Carmen",
      region: "Atacama",
      name: "Municipalidad de Alto del Carmen",
      addres: "Padre Alonso García s/n",
      image: PlaceholderImage,
      lat: -28.759696307051957,
      lng: -70.48662044052057,
    },
    {
      comuna: "Copiapó",
      region: "Atacama",
      name: "Parque Paipote",
      addres: "Juan Lopez c/ Ana Vallejos ",
      image: PlaceholderImage,
      lat: -27.412738793036667,
      lng: -70.27560685033801,
    },
    {
      comuna: "Copiapó",
      region: "Atacama",
      name: "Parque Schneider",
      addres: "C. del Parque c/ San Román ",
      image: PlaceholderImage,
      lat: -27.37583310207761,
      lng: -70.32250903872821,
    },
    {
      comuna: "Caldera",
      region: "Atacama",
      name: "Plaza Carlos Condell",
      addres: "Cousiño con Carvallo",
      image: PlaceholderImage,
      lat: -27.06778028596078,
      lng: -70.82247314752348,
    },
    {
      comuna: "Huasco",
      region: "Atacama",
      name: "Punto Limpio Sede Las Colinas",
      addres: "LOS CARRERAS 715",
      image: PlaceholderImage,
      lat: -28.467288180621306,
      lng: -71.21596021634473,
    },
    {
      comuna: "Caldera",
      region: "Atacama",
      name: "Punto Verde Almeyda",
      addres: "Avenida Diego de Almeyda # 310",
      image: PlaceholderImage,
      lat: -27.06636082321693,
      lng: -70.81744660519595,
    },
    {
      comuna: "LA SERENA",
      region: "Coquimbo",
      name: "PL SODIMAC LA SERENA",
      addres: "Av. Balmaceda 2885, La Serena, Región de Coquimbo",
      image: PlaceholderImage,
      lat: -29.92533821347375,
      lng: -71.25772840395312,
    },
    {
      comuna: "LA SERENA",
      region: "Coquimbo",
      name: "Instituto Teletón Coquimbo",
      addres: "Avenida Cuatro Esquinas 060, La Serena.",
      image: PlaceholderImage,
      lat: -29.929015204288778,
      lng: -71.2728099054106,
    },
    {
      comuna: "Paihuano",
      region: "Coquimbo",
      name: "Alcochuaz",
      addres: "-",
      image: PlaceholderImage,
      lat: -30.22964505531291,
      lng: -70.49291355545775,
    },
    {
      comuna: "La Serena",
      region: "Coquimbo",
      name: "Base CCU - Recinto Municipal ",
      addres: "Calle Balmaceda    -  Sector Centro ",
      image: PlaceholderImage,
      lat: -29.90212704433917,
      lng: -71.2498785914713,
    },
    {
      comuna: "Paihuano",
      region: "Coquimbo",
      name: "Cochiguaz",
      addres: "-",
      image: PlaceholderImage,
      lat: -30.148936609630542,
      lng: -70.38487976440345,
    },
    {
      comuna: "La Serena",
      region: "Coquimbo",
      name: "Delegación Muncicipal Pampa ",
      addres: "Larrain Alcalde  3500 Local 7 Edificio Sonora  ",
      image: PlaceholderImage,
      lat: -29.934734324681823,
      lng: -71.25710201716305,
    },
    {
      comuna: "La Serena",
      region: "Coquimbo",
      name: "Delegación Municipal Antena ",
      addres: "Av. 18 de Septiembre s/n   - La Antena ",
      image: PlaceholderImage,
      lat: -29.915360741257068,
      lng: -71.21899202745712,
    },
    {
      comuna: "La Serena",
      region: "Coquimbo",
      name: "Delegación Municipal Av. Del Mar .",
      addres: "Av. Costanera ",
      image: PlaceholderImage,
      lat: -29.919903744135073,
      lng: -71.27617089392552,
    },
    {
      comuna: "La Serena",
      region: "Coquimbo",
      name: "Delegación Municipal Las Compañías ",
      addres: "Esmeralda 2422 - Las Compañías ",
      image: PlaceholderImage,
      lat: -29.877973426807984,
      lng: -71.24577855720779,
    },
    {
      comuna: "La Serena",
      region: "Coquimbo",
      name: "Dirección de Servicio a la Comunidad ",
      addres: "Calle Colón 824 - Sector Centro ",
      image: PlaceholderImage,
      lat: -29.89943370228003,
      lng: -71.24468112533548,
    },
    {
      comuna: "Punitaqui",
      region: "Coquimbo",
      name: "Frontis Municipal",
      addres: "Caupolicán N° 1147",
      image: PlaceholderImage,
      lat: -30.833749900129547,
      lng: -71.25819067615463,
    },
    {
      comuna: "Paihuano",
      region: "Coquimbo",
      name: "Horcon Bajo",
      addres: "-",
      image: PlaceholderImage,
      lat: -30.195791636572743,
      lng: -70.4838486606194,
    },
    {
      comuna: "Paihuano",
      region: "Coquimbo",
      name: "Las Placetas, Horcon",
      addres: "-",
      image: PlaceholderImage,
      lat: -30.187776638491304,
      lng: -70.48425714125943,
    },
    {
      comuna: "Paihuano",
      region: "Coquimbo",
      name: "Monte Gabriela",
      addres: "-",
      image: PlaceholderImage,
      lat: -30.089430578448216,
      lng: -70.49941147591,
    },
    {
      comuna: "Paihuano",
      region: "Coquimbo",
      name: "Monte Grande",
      addres: "-",
      image: PlaceholderImage,
      lat: -30.09438099100144,
      lng: -70.49502344279179,
    },
    {
      comuna: "Paihuano",
      region: "Coquimbo",
      name: "Pisco Elqui",
      addres: "-",
      image: PlaceholderImage,
      lat: -30.123894109615197,
      lng: -70.49557988586604,
    },
    {
      comuna: "Paihuano",
      region: "Coquimbo",
      name: "Población Los Nogales",
      addres: "-",
      image: PlaceholderImage,
      lat: -30.131693703261984,
      lng: -70.49776562996576,
    },
    {
      comuna: "Coquimbo",
      region: "Coquimbo",
      name: "Punto limpio El Culebron",
      addres: "Av. México 607, Sector San Juan, Coquimbo",
      image: PlaceholderImage,
      lat: -29.977658027235364,
      lng: -71.32643495255424,
    },
    {
      comuna: "Ovalle",
      region: "Coquimbo",
      name: "Punto Limpio Ovalle ",
      addres: "Los Industriales S/N, Barrio Industrial Ovalle",
      image: PlaceholderImage,
      lat: -30.61006527232827,
      lng: -71.19289170567123,
    },
    {
      comuna: "Paihuano",
      region: "Coquimbo",
      name: "Punto Sector Chanchoqui",
      addres: "-",
      image: PlaceholderImage,
      lat: -30.029404182268415,
      lng: -70.52489404734497,
    },
    {
      comuna: "Punitaqui",
      region: "Coquimbo",
      name: "Punto Verde Bernardo O'Higgins",
      addres: "Los Mantos Con Av. Las Torres, Población Bernardo O’Higgins",
      image: PlaceholderImage,
      lat: -30.832300418398507,
      lng: -71.25475400194178,
    },
    {
      comuna: "Punitaqui",
      region: "Coquimbo",
      name: "Punto Verde La Silleta",
      addres: "Calle Única La Silleta S/N",
      image: PlaceholderImage,
      lat: -30.82792785264591,
      lng: -71.25016080493043,
    },
    {
      comuna: "Punitaqui",
      region: "Coquimbo",
      name: "Punto Verde Las Ramadas",
      addres: "Luis Cruz Martinez S/N, Villa Los Héroes. Las Ramadas",
      image: PlaceholderImage,
      lat: -30.7913528619468,
      lng: -71.25445714855954,
    },
    {
      comuna: "Punitaqui",
      region: "Coquimbo",
      name: "Punto Verde Las Tres Villas",
      addres:
        "Calle San Pedro De Quiles Con Pasaje Portezuelos Blancos. Población. Las Tres Villas",
      image: PlaceholderImage,
      lat: -30.827770542023213,
      lng: -71.25614872146939,
    },
    {
      comuna: "Punitaqui",
      region: "Coquimbo",
      name: "Punto Verde Villa El Estero",
      addres: "Callejón Los Lazos S/N, Villa El Estero",
      image: PlaceholderImage,
      lat: -30.835491211387268,
      lng: -71.25871337832452,
    },
    {
      comuna: "Punitaqui",
      region: "Coquimbo",
      name: "Punto Verde Villa Pueblo Nuevo",
      addres: "Calle 1 S/N, Villa Pueblo Nuevo",
      image: PlaceholderImage,
      lat: -30.847465012506365,
      lng: -71.25000349349541,
    },
    {
      comuna: "Punitaqui",
      region: "Coquimbo",
      name: "Punto Verde Villa San Antonio",
      addres: "Calle Villa San Antonio S/N, La Higuera",
      image: PlaceholderImage,
      lat: -30.869776467902604,
      lng: -71.25485466636954,
    },
    {
      comuna: "Paihuano",
      region: "Coquimbo",
      name: "Quebrada Paihuano",
      addres: "-",
      image: PlaceholderImage,
      lat: -30.03310272137208,
      lng: -70.4604420855409,
    },
    {
      comuna: "Paihuano",
      region: "Coquimbo",
      name: "Quebrada Pinto",
      addres: "-",
      image: PlaceholderImage,
      lat: -30.067504920933136,
      lng: -70.49472407803924,
    },
    {
      comuna: "Paihuano",
      region: "Coquimbo",
      name: "Tres Cruces Alto",
      addres: "-",
      image: PlaceholderImage,
      lat: -29.996894714761446,
      lng: -70.5343623880698,
    },
    {
      comuna: "Paihuano",
      region: "Coquimbo",
      name: "Tres Cruces Bajo",
      addres: "-",
      image: PlaceholderImage,
      lat: -29.99226727000701,
      lng: -70.5402431476508,
    },
    {
      comuna: "Paihuano",
      region: "Coquimbo",
      name: "Villa Los Cantaros",
      addres: "-",
      image: PlaceholderImage,
      lat: -30.029472295930713,
      lng: -70.52224190319784,
    },
    {
      comuna: "VALPARAISO",
      region: "Valparaíso",
      name: "PL SODIMAC LA CALERA",
      addres: "J. J. Pérez 12010, La Calera, Calera, Región de Valparaíso",
      image: PlaceholderImage,
      lat: -32.790179591520555,
      lng: -71.19110360019647,
    },
    {
      comuna: "VIÑA DEL MAR",
      region: "Valparaíso",
      name: "PL SODIMAC REÑACA VIÑA",
      addres: "Alessandri 1255",
      image: PlaceholderImage,
      lat: -32.99893343136355,
      lng: -71.51338457320496,
    },
    {
      comuna: "ZAPALLAR",
      region: "Valparaíso",
      name: "PL ZAPALLAR MUNICIPALIDAD",
      addres: "Sector El Maitén (camino a Cachagua) ruta E30F KM 30",
      image: PlaceholderImage,
      lat: -32.58574914079987,
      lng: -71.43670834873139,
    },
    {
      comuna: "VALPARAISO",
      region: "Valparaíso",
      name: "Instituto Teletón Valparaíso",
      addres: "Avda. Francia 259",
      image: PlaceholderImage,
      lat: -33.04476369820136,
      lng: -71.61207196136783,
    },
    {
      comuna: "Valparaíso",
      region: "Valparaíso",
      name: "Parque Italia",
      addres: "Av. Pedro Montt esquina Freire Valparaíso, Valparaíso",
      image: PlaceholderImage,
      lat: -33.04791667,
      lng: -71.61433333,
    },
    {
      comuna: "Valparaíso",
      region: "Valparaíso",
      name: "Plaza Sotomayor",
      addres: "calle Serrano con Errázuriz, Valpraíso",
      image: PlaceholderImage,
      lat: -33.03846667,
      lng: -71.62876667,
    },
    {
      comuna: "Valparaíso",
      region: "Valparaíso",
      name: "Plaza Victoria",
      addres: "Calle Plaza Victoria, Valparaíso",
      image: PlaceholderImage,
      lat: -33.04624167,
      lng: -71.61980833,
    },
    {
      comuna: "Quintero",
      region: "Valparaíso",
      name: "Departamento Medio Ambiente",
      addres: "21 de Mayo N°1545",
      image: PlaceholderImage,
      lat: -32.78194,
      lng: -71.527418,
    },
    {
      comuna: "San Esteban",
      region: "Valparaíso",
      name: "Dideco San Esteban ",
      addres: "avenida Alessandri # 150,DIDECO San Esteban",
      image: PlaceholderImage,
      lat: -32.8004181433741,
      lng: -70.5821428580113,
    },
    {
      comuna: "San Antonio",
      region: "Valparaíso",
      name: "Frontis Municipal",
      addres: "Av. Barros Luco 1881 (Frontis Municipalidad de San Antonio)",
      image: PlaceholderImage,
      lat: -33.596084233221546,
      lng: -71.61365328764853,
    },
    {
      comuna: "La Calera",
      region: "Valparaíso",
      name: "Ilustre Municipalidad de La Calera",
      addres: "Avenida Marathon Nº 312",
      image: PlaceholderImage,
      lat: -32.78890590471908,
      lng: -71.20063020300569,
    },
    {
      comuna: "ZAPALLAR",
      region: "Valparaíso",
      name: "PUNTO LIMPIO CACHAGUA",
      addres: "RUTA F30 FRENTE AL CAMPO DE GOLF DE CACHAGUA ",
      image: PlaceholderImage,
      lat: -32.58450940118242,
      lng: -71.44346818564867,
    },
    {
      comuna: "Villa Alemana",
      region: "Valparaíso",
      name: "Punto limpio Plaza Belén",
      addres: "Buenos Aires (entre Blanco y Latorre)",
      image: PlaceholderImage,
      lat: -33.042887848911334,
      lng: -71.3741835898164,
    },
    {
      comuna: "La Ligua",
      region: "Valparaíso",
      name: "Municipalidad de La Ligua",
      addres: "Portales 555",
      image: PlaceholderImage,
      lat: -32.45003174494299,
      lng: -71.23145376898,
    },
    {
      comuna: "La Ligua",
      region: "Valparaíso",
      name: "Acopio Rotonda Pullally",
      addres:
        "Ruta 5 Norte, Lote 276-A1 y Lote 273, Rotonda de Pullally. Acceso camino interior Pullally ",
      image: PlaceholderImage,
      lat: -32.44482517233747,
      lng: -71.31957832809621,
    },
    {
      comuna: "LAS CONDES",
      region: "RM",
      name: "KIDZANIA",
      addres: "Presidente Riesco 5330 Nivel Boulevard 2, Vida Parque",
      image: PlaceholderImage,
      lat: -33.4032585507477,
      lng: -70.5752054048385,
    },
    {
      comuna: "PROVIDENCIA",
      region: "RM",
      name: "PL PARQUE METROPOLITANO",
      addres: "Avenida El Cerro s/n, entrada Pedro de Valdivia Norte",
      image: PlaceholderImage,
      lat: -33.415229645648516,
      lng: -70.61685848061163,
    },
    {
      comuna: "LAS CONDES",
      region: "RM",
      name: "PL SODIMAC / MALL PLAZA LOS DOMINICOS",
      addres: "Padre Hurtado Sur 875",
      image: PlaceholderImage,
      lat: -33.41536628433372,
      lng: -70.54029911737324,
    },
    {
      comuna: "CERRILLOS",
      region: "RM",
      name: "PL SODIMAC CERRILLOS",
      addres: "Avenida Américo Vespucio 1501",
      image: PlaceholderImage,
      lat: -33.518397,
      lng: -70.714824,
    },
    {
      comuna: "LA REINA",
      region: "RM",
      name: "PL SODIMAC LA REINA",
      addres: "Avenida Jorge Alessandri 1347",
      image: PlaceholderImage,
      lat: -33.46214112846809,
      lng: -70.54661651633529,
    },
    {
      comuna: "MELIPILLA",
      region: "RM",
      name: "PL SODIMAC MELIPILLA",
      addres: "Av, Vicuña Mackenna 1415",
      image: PlaceholderImage,
      lat: -33.67540866860537,
      lng: -71.16731843086093,
    },
    {
      comuna: "PEÑALOLEN",
      region: "RM",
      name: "PL SODIMAC PEÑALOLEN",
      addres: "Avenida José Pedro Alessandri N°6402",
      image: PlaceholderImage,
      lat: -33.50887996672317,
      lng: -70.58827765970015,
    },
    {
      comuna: "QUILICURA",
      region: "RM",
      name: "PL SODIMAC QUILICURA",
      addres: "Avenida Manuel Antonio Matta 581",
      image: PlaceholderImage,
      lat: -33.36545271029902,
      lng: -70.72713815785495,
    },
    {
      comuna: "SAN BERNARDO",
      region: "RM",
      name: "PL SODIMAC SAN BERNARDO",
      addres: "Avenida Jorge Alessandri Rodriguez 20040",
      image: PlaceholderImage,
      lat: -33.554063,
      lng: -70.675761,
    },
    {
      comuna: "LAS CONDES",
      region: "RM",
      name: "PUNTO CENTRAL TEMPORAL PARQUE ARAUCANO",
      addres: "Av. Pdte. Kennedy 5413",
      image: PlaceholderImage,
      lat: -33.40153477111084,
      lng: -70.57709760585323,
    },
    {
      comuna: "LAS CONDES",
      region: "RM",
      name: "UDD PL LAS CONDES",
      addres: "Avenida la Plaza 680",
      image: PlaceholderImage,
      lat: -33.39166026698991,
      lng: -70.50097914620927,
    },
    {
      comuna: "PUENTE ALTO",
      region: "RM",
      name: "WALMART PL CORDILLERA",
      addres: "Avenida Los Toros 5441",
      image: PlaceholderImage,
      lat: -33.56715598559155,
      lng: -70.55817520387626,
    },
    {
      comuna: "LA FLORIDA",
      region: "RM",
      name: "WALMART PL La Florida",
      addres: "Av. Americo Vespucio 6325, La Florida",
      image: PlaceholderImage,
      lat: -33.352936,
      lng: -70.66913,
    },
    {
      comuna: "LA REINA",
      region: "RM",
      name: "WALMART PL PRINCIPE DE GALES",
      addres: "Príncipe de Gales 9140",
      image: PlaceholderImage,
      lat: -33.44070058956589,
      lng: -70.53517825785322,
    },
    {
      comuna: "Santiago",
      region: "RM",
      name: "Instituto Teletón Santiago",
      addres: "Avda. Libertador Bernardo O’Higgins 4620",
      image: PlaceholderImage,
      lat: -33.45614673683549,
      lng: -70.69897578465805,
    },
    {
      comuna: "Pudahuel",
      region: "RM",
      name: "BNUP (frente a cancha municipal)",
      addres: "Av. La Travesía  &  Santa Gemita",
      image: PlaceholderImage,
      lat: -33.458608972710294,
      lng: -70.73296262371224,
    },
    {
      comuna: "Pudahuel",
      region: "RM",
      name: "Cancha J.J.V.V Jardines del Sur",
      addres: "Ara  &  Casiopea",
      image: PlaceholderImage,
      lat: -33.46350082240922,
      lng: -70.75474983810726,
    },
    {
      comuna: "Pudahuel",
      region: "RM",
      name: "Centro Cultural La Estrella Sur",
      addres: "Los Mares 8525",
      image: PlaceholderImage,
      lat: -33.46518238957239,
      lng: -70.75399172761956,
    },
    {
      comuna: "Pirque",
      region: "RM",
      name: "Centro de salud Rural El Principal ",
      addres: "Camino La Alameda s/n sector El Principal, Pirque",
      image: PlaceholderImage,
      lat: -33.720558179130784,
      lng: -70.55327496139485,
    },
    {
      comuna: "La Florida",
      region: "RM",
      name: "Centro del Cariño",
      addres: "Av. Vicuña Mackenna 7210",
      image: PlaceholderImage,
      lat: -33.52049450590217,
      lng: -70.60137624930091,
    },
    {
      comuna: "Quilicura",
      region: "RM",
      name: "Centro Inclusivo Municipal",
      addres: "Raimundo Romo #280",
      image: PlaceholderImage,
      lat: -33.36705007257598,
      lng: -70.73154964042583,
    },
    {
      comuna: "Pirque",
      region: "RM",
      name: "Cesfam Balmaceda",
      addres: "Av. Ramón Subercaseaux 87 ",
      image: PlaceholderImage,
      lat: -33.634994653677005,
      lng: -70.57246098437462,
    },
    {
      comuna: "Pudahuel",
      region: "RM",
      name: "CESFAM Pudahuel La Estrella",
      addres: "Av. San Francisco 8698",
      image: PlaceholderImage,
      lat: -33.437023737330506,
      lng: -70.75075790790805,
    },
    {
      comuna: "Pudahuel",
      region: "RM",
      name: "CESFAM Violeta Parra",
      addres: "Av. La Travesía  7447",
      image: PlaceholderImage,
      lat: -33.457142507716895,
      lng: -70.74289950483504,
    },
    {
      comuna: "Pudahuel",
      region: "RM",
      name: "Ciudad de los Valles",
      addres: "Bandejón Av. Las Flores con Las Brisas",
      image: PlaceholderImage,
      lat: -33.45651843499561,
      lng: -70.84504815216269,
    },
    {
      comuna: "Quilicura",
      region: "RM",
      name: "Colegio Luis Cruz Martínez",
      addres: "Av. Manuel Antonio Matta 1250",
      image: PlaceholderImage,
      lat: -33.367002328666246,
      lng: -70.71643769134933,
    },
    {
      comuna: "La Florida",
      region: "RM",
      name: "Dirección de Medio Ambiente, Aseo, Ornato y Operaciones",
      addres: "Calle Tres 10.050",
      image: PlaceholderImage,
      lat: -33.54761488046956,
      lng: -70.60973208514346,
    },
    {
      comuna: "La Florida",
      region: "RM",
      name: "Edificio San Pablo",
      addres: "Av. Vicuña Mackenna 10.777",
      image: PlaceholderImage,
      lat: -33.558391081541686,
      lng: -70.58613074275706,
    },
    {
      comuna: "Pudahuel",
      region: "RM",
      name: "Farmacia Popular",
      addres: "Puerto Arturo N°8261.",
      image: PlaceholderImage,
      lat: -33.46161742161349,
      lng: -70.74671777599862,
    },
    {
      comuna: "San José de Maipo",
      region: "RM",
      name: "Frontis del Municipio",
      addres: "Camino El Volcán 19.775 ",
      image: PlaceholderImage,
      lat: -33.58754064794646,
      lng: -70.38916830482712,
    },
    {
      comuna: "Quilicura",
      region: "RM",
      name: "Frontis Municipal",
      addres: "Punto limpio móvil días 10 y 11 de noviembre ",
      image: PlaceholderImage,
      lat: -33.36903481202247,
      lng: -70.73192446738828,
    },
    {
      comuna: "San Miguel ",
      region: "RM",
      name: "Frontis Municipalidad",
      addres: "Gran Avenida Jose Miguel Carrera 3418 ",
      image: PlaceholderImage,
      lat: -33.485873078277635,
      lng: -70.64968348197928,
    },
    {
      comuna: "La Florida",
      region: "RM",
      name: "Gimnasio Municipal",
      addres: "Alonso de Ercilla 6698",
      image: PlaceholderImage,
      lat: -33.51717417983633,
      lng: -70.58858614427372,
    },
    {
      comuna: "Pudahuel",
      region: "RM",
      name: "Municipalidad",
      addres: "San Pablo N° 8444.",
      image: PlaceholderImage,
      lat: -33.44485908413454,
      lng: -70.74424751313927,
    },
    {
      comuna: "Paine",
      region: "RM",
      name: "Municipalidad de Paine",
      addres: "Av. General Baquedano 490",
      image: PlaceholderImage,
      lat: -33.807699786089465,
      lng: -70.7418661225278,
    },
    {
      comuna: "Pirque",
      region: "RM",
      name: "Municipalidad de Pirque ",
      addres: "Av. Concha y toro 02548",
      image: PlaceholderImage,
      lat: -33.63480532483328,
      lng: -70.57321969386119,
    },
    {
      comuna: "Pudahuel",
      region: "RM",
      name: "Oficina Municipal de Noviciado",
      addres: "Av. Simón Bolívar",
      image: PlaceholderImage,
      lat: -33.40203685684951,
      lng: -70.85572097730794,
    },
    {
      comuna: "Pudahuel",
      region: "RM",
      name: "Peralito",
      addres: "Alejandro Dip, esquina Narciso Ortega",
      image: PlaceholderImage,
      lat: -33.383849244256986,
      lng: -70.80926068014847,
    },
    {
      comuna: "El Monte",
      region: "RM",
      name: "Plaza Chiñigue Los Quilos",
      addres: "Av. Chiñigue los Quilos",
      image: PlaceholderImage,
      lat: -33.68989948230334,
      lng: -71.0803473077267,
    },
    {
      comuna: "Santiago",
      region: "RM",
      name: "Plaza de Bolsillo Santo Domingo",
      addres: "Santo Domingo esquina de Teatinos ",
      image: PlaceholderImage,
      lat: -33.4367061730111,
      lng: -70.65533720791174,
    },
    {
      comuna: "El Monte",
      region: "RM",
      name: "Plaza De Los Porotos",
      addres: "Av. Los Carrera",
      image: PlaceholderImage,
      lat: -33.67812449162439,
      lng: -70.99248420296787,
    },
    {
      comuna: "El Monte",
      region: "RM",
      name: "Plaza Independencia",
      addres: "Av. Los Libertadores",
      image: PlaceholderImage,
      lat: -33.67835914814516,
      lng: -70.97816163365772,
    },
    {
      comuna: "Pudahuel",
      region: "RM",
      name: "Plaza Lo Espejo",
      addres: "Lo Espejo  &  26 de Enero",
      image: PlaceholderImage,
      lat: -33.43668097142447,
      lng: -70.76121590914089,
    },
    {
      comuna: "Maipú",
      region: "RM",
      name: "Plaza Maipú",
      addres: "Av. Pajaritos con Av. 5 de Abril",
      image: PlaceholderImage,
      lat: -33.509489083015936,
      lng: -70.75623794131741,
    },
    {
      comuna: "Renca",
      region: "RM",
      name: "Plaza Mayor de Renca",
      addres: "Frente a Blanco Encalada 1335",
      image: PlaceholderImage,
      lat: -33.40449182550319,
      lng: -70.70461874534445,
    },
    {
      comuna: "Pudahuel",
      region: "RM",
      name: "Plaza Teniente Merino",
      addres: "Copiapó con Las Arvejas",
      image: PlaceholderImage,
      lat: -33.432851007951975,
      lng: -70.76030377956751,
    },
    {
      comuna: "Pudahuel",
      region: "RM",
      name: "PSA",
      addres: "Acceso Ediles con Corona Sueca.",
      image: PlaceholderImage,
      lat: -33.44824280091525,
      lng: -70.74432657302965,
    },
    {
      comuna: "Pudahuel",
      region: "RM",
      name: "PSA",
      addres: "Acceso La Estrella 711",
      image: PlaceholderImage,
      lat: -33.44684852030219,
      lng: -70.75387915403759,
    },
    {
      comuna: "La Pintana ",
      region: "RM",
      name: "Puerta 2 Edificio Consistorial Municipal",
      addres: "Avenida Santa Rosa  N°12.975",
      image: PlaceholderImage,
      lat: -33.58406308711146,
      lng: -70.63113695405373,
    },
    {
      comuna: "Vitacura ",
      region: "RM",
      name: "Punto Limpio",
      addres: "Av. San Josemaría Escrivá de Balaguer 4275, Vitacura",
      image: PlaceholderImage,
      lat: -33.39127061555938,
      lng: -70.5992569336753,
    },
    {
      comuna: "El Monte",
      region: "RM",
      name: "Punto Limpio Comunal",
      addres: "Ignacio Carrera Pinto 048",
      image: PlaceholderImage,
      lat: -33.68026800351395,
      lng: -70.97886940888577,
    },
    {
      comuna: "Puente Alto",
      region: "RM",
      name: "Punto Limpio Juan de Dios Malebrán",
      addres: "Calle Juan de Dios Malebrán # 1147",
      image: PlaceholderImage,
      lat: -33.60080215373738,
      lng: -70.58812453366555,
    },
    {
      comuna: "Lo Barnechea",
      region: "RM",
      name: "Punto Limpio Lo Barnechea",
      addres: "Av. Raúl Labbé 12.099 (frente al Mampato)",
      image: PlaceholderImage,
      lat: -33.36788038747213,
      lng: -70.51880780025412,
    },
    {
      comuna: "Puente Alto",
      region: "RM",
      name: "Punto Limpio Los Toros",
      addres: "Av. Camilo Henríquez # 3686",
      image: PlaceholderImage,
      lat: -33.56662939431441,
      lng: -70.55498192853773,
    },
    {
      comuna: "El Monte",
      region: "RM",
      name: "Punto Limpio Villa América",
      addres: "Camino Argentina S/N",
      image: PlaceholderImage,
      lat: -33.68920331924033,
      lng: -71.03524454944598,
    },
    {
      comuna: "Conchalí ",
      region: "RM",
      name: "Punto verde DIMAO",
      addres: "Av. La palmilla 3673 ",
      image: PlaceholderImage,
      lat: -33.39213284941733,
      lng: -70.66802337600284,
    },
    {
      comuna: "Buin",
      region: "RM",
      name: "Punto verde Municipal",
      addres: "Alberto Krumm 024",
      image: PlaceholderImage,
      lat: -33.72820721422419,
      lng: -70.73694987261618,
    },
    {
      comuna: "Pudahuel",
      region: "RM",
      name: "Quiero mi Barrio Monseñor Larraín",
      addres: "Las Almerías  &  Las Cadenas",
      image: PlaceholderImage,
      lat: -33.436738443459,
      lng: -70.75743174457463,
    },
    {
      comuna: "Pudahuel",
      region: "RM",
      name: "Villa El Cabildo",
      addres: "Los Mares  &  La Estrella",
      image: PlaceholderImage,
      lat: -33.46521937440987,
      lng: -70.75336431278514,
    },
    {
      comuna: "El Monte",
      region: "RM",
      name: "Villa O’Higgins",
      addres: "Av. Los Libertadores",
      image: PlaceholderImage,
      lat: -33.688330115584876,
      lng: -71.05098049200538,
    },
    {
      comuna: "LAS CONDES",
      region: "RM",
      name: "ED. CORPORATIVO VITACURA",
      addres: "Av Vitacura 2670, Las Condes",
      image: PlaceholderImage,
      lat: -33.41639993603366,
      lng: -70.60459600267949,
    },
    {
      comuna: "MAIPU",
      region: "RM",
      name: "Avenida Pajaritos - MAIPU",
      addres: "Av Pajaritos con 5 de Abril",
      image: PlaceholderImage,
      lat: -33.50972592637083,
      lng: -70.75681000267636,
    },
    {
      comuna: "ÑUÑOA",
      region: "RM",
      name: "Irarrázaval - NUÑOA",
      addres: "Av. Irarrázaval 3579",
      image: PlaceholderImage,
      lat: -33.455140328075096,
      lng: -70.59356258830623,
    },
    {
      comuna: "RENCA ",
      region: "RM",
      name: "PLANTA MODELO",
      addres: "Av. Eduardo Frei Montalva 1500",
      image: PlaceholderImage,
      lat: -33.409569737658884,
      lng: -70.6803463625105,
    },
    {
      comuna: "RENCA ",
      region: "RM",
      name: "Plaza de Renca",
      addres: "Blanco Encalada 1335",
      image: PlaceholderImage,
      lat: -33.404539459026104,
      lng: -70.7046364600594,
    },
    {
      comuna: "RANCAGUA",
      region: "O'Higgins",
      name: "PL SODIMAC RANCAGUA",
      addres: "Avenida Koke 011",
      image: PlaceholderImage,
      lat: -34.16400443269548,
      lng: -70.75196124434365,
    },
    {
      comuna: "COINCO",
      region: "O'Higgins",
      name: " H-38 250, Coinco, O'Higgins",
      addres: "PLAZA COINCO",
      image: PlaceholderImage,
      lat: -34.26949511371661,
      lng: -70.95164500294953,
    },
    {
      comuna: "Rancagua",
      region: "O'Higgins",
      name: "barrio los pinares",
      addres: "Llacantu con Eyentu (area verde)",
      image: PlaceholderImage,
      lat: -34.1486439,
      lng: -70.7047747,
    },
    {
      comuna: "PUMANQUE",
      region: "O'Higgins",
      name: "CENTRO CULTURAL PUMANQUE",
      addres: "AV.MANUEL RODRIGUEZ S/N PUMANQUE CENTRO CULRUTAL",
      image: PlaceholderImage,
      lat: -34.59604073882081,
      lng: -71.65076638944343,
    },
    {
      comuna: "LOLOL",
      region: "O'Higgins",
      name: "ESTADIO MUNICIPAL",
      addres: "IGNACIO CARRERA PINTO NUMERO 2",
      image: PlaceholderImage,
      lat: -33.46820867678072,
      lng: -70.59335679688074,
    },
    {
      comuna: "machali",
      region: "O'Higgins",
      name: "GARAJES MUNICIPALES",
      addres: "AV.SAN JUAN #1200 INTERIOR ",
      image: PlaceholderImage,
      lat: -34.176557417629795,
      lng: -70.65862583628287,
    },
    {
      comuna: "PAREDONES",
      region: "O'Higgins",
      name: "MUNICIPALIDAD  PAREDONES",
      addres: "DOCTOR MUA #15",
      image: PlaceholderImage,
      lat: -34.648576829828805,
      lng: -71.89969295503472,
    },
    {
      comuna: "machali",
      region: "O'Higgins",
      name: "oficina municipal coya",
      addres: "oficina municipal localidad coya ,san martin #100 ",
      image: PlaceholderImage,
      lat: -34.20627456054693,
      lng: -70.53044600643189,
    },
    {
      comuna: "la estrella",
      region: "O'Higgins",
      name: "PLAZA CÍVICA",
      addres: "Bernardo  O´Higgins S/N (frente al Municipio",
      image: PlaceholderImage,
      lat: -34.20526875516139,
      lng: -71.65472604616372,
    },
    {
      comuna: "LITUECHE",
      region: "O'Higgins",
      name: "PLAZOLETA MUNICIPAL",
      addres: "CARDENAL CARO 796",
      image: PlaceholderImage,
      lat: -34.11556147902898,
      lng: -71.72960607795054,
    },
    {
      comuna: "Rancagua",
      region: "O'Higgins",
      name: "terrazas de lourde",
      addres: "Monte Aguila Oriente Esquina Rio loco",
      image: PlaceholderImage,
      lat: -34.18190107030028,
      lng: -70.762704858057,
    },
    {
      comuna: "PLACILLA",
      region: "O'Higgins",
      name: null,
      addres: "Oscar Gajardo # 2250",
      image: PlaceholderImage,
      lat: -34.63727742653771,
      lng: -71.11831767594828,
    },
    {
      comuna: "COINCO",
      region: "O'Higgins",
      name: "Plaza Coinco",
      addres: "H-38, 250 Coinco",
      image: PlaceholderImage,
      lat: -34.26835501299158,
      lng: -70.94928208702467,
    },
    {
      comuna: "Peralillo ",
      region: "O'Higgins",
      name: "Municipalidad de Peralillo",
      addres: "Bdo O'Higgins, Peralillo",
      image: PlaceholderImage,
      lat: -34.47824436665794,
      lng: -71.4785740595676,
    },
    {
      comuna: "TALCA",
      region: "Maule",
      name: "PL SODIMAC TALCA",
      addres: "avenida 2 nte 3344",
      image: PlaceholderImage,
      lat: -35.43141889914438,
      lng: -71.62929133082105,
    },
    {
      comuna: "TALCA",
      region: "Maule",
      name: "Instituto Teletón Del Maule",
      addres: "10 Ote. 3073, Talca, Maule",
      image: PlaceholderImage,
      lat: -35.405603853855425,
      lng: -71.6426327892781,
    },
    {
      comuna: "Talca",
      region: "Maule",
      name: "Bicentenario",
      addres: "32 oriente 22 1/2 norte B",
      image: PlaceholderImage,
      lat: -35.41390492712296,
      lng: -71.6112480722383,
    },
    {
      comuna: "Talca",
      region: "Maule",
      name: "Carlos Trupp",
      addres: "30 oriente nueve 1/2  sur",
      image: PlaceholderImage,
      lat: -35.443124421709165,
      lng: -71.63442589738027,
    },
    {
      comuna: "Linares",
      region: "Maule",
      name: "Casa de la cultura",
      addres: "Av Valentín Letelier/ Manuel Rodríguez",
      image: PlaceholderImage,
      lat: -35.84890984694369,
      lng: -71.59820622059075,
    },
    {
      comuna: "Talca",
      region: "Maule",
      name: "Circunvalacion",
      addres: "5 oriente con Circunvalacion",
      image: PlaceholderImage,
      lat: -35.39655579147167,
      lng: -71.65164689585534,
    },
    {
      comuna: "Talca",
      region: "Maule",
      name: "Colines",
      addres: "10 oriente 16 sur",
      image: PlaceholderImage,
      lat: -35.44822836951077,
      lng: -71.65609788942355,
    },
    {
      comuna: "Parral",
      region: "Maule",
      name: "Depto medio ambiente",
      addres: "Tarpacá #464",
      image: PlaceholderImage,
      lat: -36.14217833113176,
      lng: -71.8325399065188,
    },
    {
      comuna: "Romeral",
      region: "Maule",
      name: "Dideco Romeral",
      addres: "Avenida libertad (costado direccion desarrollo comunitario)",
      image: PlaceholderImage,
      lat: -34.959585,
      lng: -71.12638,
    },
    {
      comuna: "Linares",
      region: "Maule",
      name: "Escuela Carlos Ibáñez del Campo",
      addres: "Sta María & Patricio Lynch, Patricio Lynch 299",
      image: PlaceholderImage,
      lat: -35.85702146459086,
      lng: -71.5808173558236,
    },
    {
      comuna: "Linares",
      region: "Maule",
      name: "Escuela Margot Loyola",
      addres: "Avda. Yungay 833",
      image: PlaceholderImage,
      lat: -35.84059201168335,
      lng: -71.6019773017349,
    },
    {
      comuna: "Curico",
      region: "Maule",
      name: "Estadio La Granja",
      addres: "Avenida Freire",
      image: PlaceholderImage,
      lat: -34.97698050695182,
      lng: -71.23175782459808,
    },
    {
      comuna: "Romeral",
      region: "Maule",
      name: "Estadio Romeral",
      addres: "Av. Oscar bonilla (frente al estadio Municipal)",
      image: PlaceholderImage,
      lat: -34,
      lng: 962631,
    },
    {
      comuna: "Talca",
      region: "Maule",
      name: "Faustino Gonzalez",
      addres: "17 oriente 17 norte",
      image: PlaceholderImage,
      lat: -35.41771283677813,
      lng: -71.63657438661377,
    },
    {
      comuna: "Talca",
      region: "Maule",
      name: "Florida",
      addres: "18 sur 5 1/2 poniente",
      image: PlaceholderImage,
      lat: -35.439248432783124,
      lng: -71.6804422875779,
    },
    {
      comuna: "Parral",
      region: "Maule",
      name: "Fomento productivo",
      addres: "Anibal pinto #836",
      image: PlaceholderImage,
      lat: -36.141176922738545,
      lng: -71.82074119117391,
    },
    {
      comuna: "Parral",
      region: "Maule",
      name: "Gimnasio municipal",
      addres: "Delicias norte #717",
      image: PlaceholderImage,
      lat: -36.14551838259526,
      lng: -71.8231403352108,
    },
    {
      comuna: "Linares",
      region: "Maule",
      name: "Instituto Politécnico",
      addres: "Avda. presidente Ibáñez S/N",
      image: PlaceholderImage,
      lat: -35.839477992532636,
      lng: -71.59824869265137,
    },
    {
      comuna: "Linares",
      region: "Maule",
      name: "JJVV Presidente Ibáñez",
      addres: "Calle John F. Kennedy s/n",
      image: PlaceholderImage,
      lat: -35.845915531672695,
      lng: -71.60471321165278,
    },
    {
      comuna: "Teno",
      region: "Maule",
      name: "Municipalidad de Teno",
      addres: "Arturo Prat 225, Teno ",
      image: PlaceholderImage,
      lat: -34.86726803365655,
      lng: -71.16102327564859,
    },
    {
      comuna: "Linares",
      region: "Maule",
      name: "Oficina de Turismo",
      addres: "Calle Kurt Moler / calle O “Higgins",
      image: PlaceholderImage,
      lat: -35.84462523443817,
      lng: -71.5983078561074,
    },
    {
      comuna: "Romeral",
      region: "Maule",
      name: "Plaza Romeral",
      addres: "Avenida libertad (plaza de Romeral)",
      image: PlaceholderImage,
      lat: -35,
      lng: 95977,
    },
    {
      comuna: "Pelluhue",
      region: "Maule",
      name: "Punto Verde Pelluhue",
      addres:
        "Abdón Fuentealba s/n (frente a la compañoa de bomberos en Pelluhue)",
      image: PlaceholderImage,
      lat: -35.81111,
      lng: -72.5723,
    },
    {
      comuna: "Talca",
      region: "Maule",
      name: "Villas unidas",
      addres: "Doce 1/2 oriente esquina 11 norte",
      image: PlaceholderImage,
      lat: -35.41546630472318,
      lng: -71.64608044709523,
    },
    {
      comuna: "Molina",
      region: "Maule",
      name: "Municipalidad de Molina",
      addres: "Yerbas Buenas 1389",
      image: PlaceholderImage,
      lat: -351132556311355,
      lng: -7128019392211930,
    },
    {
      comuna: "Chillán",
      region: "Ñuble",
      name: "Estadio",
      addres:
        "Estadio Bicentenario Nelson Oyarzun Arenas, calle Pedro Aguirre Cerda Nº297",
      image: PlaceholderImage,
      lat: -36.61816335836934,
      lng: -72.10789173173653,
    },
    {
      comuna: "Chillán Viejo",
      region: "Ñuble",
      name: "Parque Monumental Bernardo Ohiggins",
      addres: "Parque Monumental Bernardo Ohiggins",
      image: PlaceholderImage,
      lat: -36.62151509624639,
      lng: -72.13120669299599,
    },
    {
      comuna: "San Ignacio",
      region: "Ñuble",
      name: "Plaza de Armas",
      addres: "Plaza de Armas, San Ignacio",
      image: PlaceholderImage,
      lat: -36.799769699419784,
      lng: -72.03025677393192,
    },
    {
      comuna: "San Ignacio",
      region: "Ñuble",
      name: "Gimnasio Municipal",
      addres: "Gimnasio Municipal, San Miguel",
      image: PlaceholderImage,
      lat: -36.80075302687334,
      lng: -72.0326451548179,
    },
    {
      comuna: "San Ignacio",
      region: "Ñuble",
      name: "Liceo Polivalente",
      addres: "Liceo Polivalente, Pueblo Seco",
      image: PlaceholderImage,
      lat: -36.88076559930736,
      lng: -72.09165251810786,
    },
    {
      comuna: "San Ignacio",
      region: "Ñuble",
      name: "Colegio Polivalente",
      addres: "Colegio Polivalente Victor Jara, Quiriquina",
      image: PlaceholderImage,
      lat: -36.84086229397293,
      lng: -72.10196937236518,
    },
    {
      comuna: "Pemuco",
      region: "Ñuble",
      name: "Claudio Muñoz & Camilo Henriquez",
      addres: "Claudio Muñoz & Camilo Henriquez",
      image: PlaceholderImage,
      lat: -36.97649259550398,
      lng: -72.1015962911681,
    },
    {
      comuna: "Pemuco",
      region: "Ñuble",
      name: "Viejo Olivo",
      addres: "O'Higgins & N-87",
      image: PlaceholderImage,
      lat: -36.977493930183755,
      lng: -72.0932824637176,
    },
    {
      comuna: "Pemuco",
      region: "Ñuble",
      name: "Don Bartolomé",
      addres: "Don Bartolomé & Pasaje 1",
      image: PlaceholderImage,
      lat: -36.97347273007,
      lng: -72.09752292305215,
    },
    {
      comuna: "Yungay",
      region: "Ñuble",
      name: "Salon Multiuso",
      addres: "Salon Multiuso, Arturo Prat Nº355",
      image: PlaceholderImage,
      lat: -37.12913106909215,
      lng: -72.18824485373416,
    },
    {
      comuna: "Yungay",
      region: "Ñuble",
      name: "Estadio",
      addres: "Polideportivo Estadio Municipal, Ignacio Carrera Pinto Nª355",
      image: PlaceholderImage,
      lat: -37.12542013515709,
      lng: -72.00831607889327,
    },
    {
      comuna: "Yungay",
      region: "Ñuble",
      name: "Bodega",
      addres: "Bodega Municipal, Pisagua Nª181",
      image: PlaceholderImage,
      lat: -37.11973517069117,
      lng: -72.02272349296302,
    },
    {
      comuna: "Yungay",
      region: "Ñuble",
      name: "Gimnasio Municipal",
      addres: "Gimnasio Campanario, Av. Argentina Nª950",
      image: PlaceholderImage,
      lat: -37.125438970672356,
      lng: -72.00839578707601,
    },
    {
      comuna: "El Carmen",
      region: "Ñuble",
      name: null,
      addres: "POR DEFINIR",
      image: PlaceholderImage,
      lat: -36.89771851045564,
      lng: -72.02479501328702,
    },
    {
      comuna: "Bulnes",
      region: "Ñuble",
      name: "Evarista Ogalde Ortega",
      addres: "Km 9 Ruta Bulnes Tres Esquinas Sector Tres Esquinas",
      image: PlaceholderImage,
      lat: -36.78701602158476,
      lng: -72.20901396008708,
    },
    {
      comuna: "Bulnes",
      region: "Ñuble",
      name: "Esc. Canta Rana",
      addres: "Km 15 Ruta Bulnes Sector Canta Rana ",
      image: PlaceholderImage,
      lat: -36.803154488636615,
      lng: -72.15159443959499,
    },
    {
      comuna: "Bulnes",
      region: "Ñuble",
      name: "Esc. Coltón Quillay",
      addres: "Km 21 Sector Colton Quillay",
      image: PlaceholderImage,
      lat: -36.77882267090793,
      lng: -72.1570074771308,
    },
    {
      comuna: "Bulnes",
      region: "Ñuble",
      name: "Esc. Milahue",
      addres: "Km 18 Sector Milahue Ruta Tres Esquinas Los Marcos",
      image: PlaceholderImage,
      lat: -36.84325018404435,
      lng: -72.23704131211808,
    },
    {
      comuna: "Bulnes",
      region: "Ñuble",
      name: "Esc. Los Tilos José Andrades V.",
      addres: "Km 23 Sector Los Tilos",
      image: PlaceholderImage,
      lat: -36.88287245143607,
      lng: -72.24143763138245,
    },
    {
      comuna: "Bulnes",
      region: "Ñuble",
      name: "Esc. El Caracol ",
      addres: "Km 19 Sector El Caracol ",
      image: PlaceholderImage,
      lat: -36.80776130845263,
      lng: -72.23685535932222,
    },
    {
      comuna: "Bulnes",
      region: "Ñuble",
      name: "Esc. Pdte. Eduardo Frei Montalva",
      addres: "Carlos Condell 847",
      image: PlaceholderImage,
      lat: -36.73835820847518,
      lng: -72.30200343455631,
    },
    {
      comuna: "Bulnes",
      region: "Ñuble",
      name: "Jardín Mis Primeros Pasos",
      addres: "Psje. Gonzalez Urizar S/N Pobl 3 De Octubre",
      image: PlaceholderImage,
      lat: -36.73388290090756,
      lng: -72.30119648025665,
    },
    {
      comuna: "Bulnes",
      region: "Ñuble",
      name: "Jardín Mis Pequeños Duendecitos",
      addres: "Calle Central S/N Villa Fresia",
      image: PlaceholderImage,
      lat: -36.73824333148126,
      lng: -72.30658641320153,
    },
    {
      comuna: "Bulnes",
      region: "Ñuble",
      name: "Jardín Mis Pequeños Corazones",
      addres: "Enrique Munita 543 Pobl Matías Madariaga 1",
      image: PlaceholderImage,
      lat: -36.73647381221615,
      lng: -72.3036672614886,
    },
    {
      comuna: "Bulnes",
      region: "Ñuble",
      name: "Jardín Infancia Feliz",
      addres: "Calle El Laurel S/N Villa El Sauce Tres Esquinas",
      image: PlaceholderImage,
      lat: -36.78781097536069,
      lng: -72.20985799403205,
    },
    {
      comuna: "Bulnes",
      region: "Ñuble",
      name: "Jardín Mis Cariñositos ",
      addres: "Arturo Prat 97 Santa Clara ",
      image: PlaceholderImage,
      lat: -36.746209338520686,
      lng: -72.29473404050266,
    },
    {
      comuna: "Bulnes",
      region: "Ñuble",
      name: "Jardín Mis Angelitos",
      addres: "San José De La Dehesa Villa Cordillera Pueblo Seco ",
      image: PlaceholderImage,
      lat: -36.80017025799548,
      lng: -72.0423108409514,
    },
    {
      comuna: "Bulnes",
      region: "Ñuble",
      name: "Oficina Medio Ambiente",
      addres: "Victor Bianchi ",
      image: PlaceholderImage,
      lat: -36.7420064697344,
      lng: -72.2974720566612,
    },
    {
      comuna: "Bulnes",
      region: "Ñuble",
      name: "Gimnasio Municipal",
      addres: "Arturo Prat 240",
      image: PlaceholderImage,
      lat: -36.747578379671374,
      lng: -72.29194396898743,
    },
    {
      comuna: "Bulnes",
      region: "Ñuble",
      name: "Sede Quiero Mi Barrio",
      addres: "Pedro Lagos Esq. Ricardo Troncoso",
      image: PlaceholderImage,
      lat: -36.747112330052914,
      lng: -72.28698410431505,
    },
    {
      comuna: "Quillón",
      region: "Ñuble",
      name: "Quillón",
      addres: "N-78-O, Quillón, Ñuble",
      image: PlaceholderImage,
      lat: -36.74055987370198,
      lng: -72.46312869410119,
    },
    {
      comuna: "Quirihue",
      region: "Ñuble",
      name: "Plaza de Quirihue",
      addres: "Plaza de Quirihue",
      image: PlaceholderImage,
      lat: -36.28291,
      lng: -72.54149,
    },
    {
      comuna: "Quirihue",
      region: "Ñuble",
      name: "Punto de Reciclaje  Independencia 225",
      addres: "Punto de Reciclaje  Independencia 225",
      image: PlaceholderImage,
      lat: -36.286139,
      lng: -72.543605,
    },
    {
      comuna: "Quirihue",
      region: "Ñuble",
      name: "Mercado Municipal Avenida Prat 478",
      addres: "Mercado Municipal Avenida Prat 478",
      image: PlaceholderImage,
      lat: -36.282903,
      lng: -72.544638,
    },
    {
      comuna: "Coelemu",
      region: "Ñuble",
      name: "Plaza Civica",
      addres: "O-212 546, Coelemu, Ñuble",
      image: PlaceholderImage,
      lat: -36.48769831480077,
      lng: -72.70224237578417,
    },
    {
      comuna: "Coelemu",
      region: "Ñuble",
      name: "Punto 2",
      addres: "Punto 2",
      image: PlaceholderImage,
      lat: -36.4879890702125,
      lng: -72.70300524275588,
    },
    {
      comuna: "Ranquil",
      region: "Ñuble",
      name: "Casa de la Cultura",
      addres: "Casa de la Cultura",
      image: PlaceholderImage,
      lat: -36.60356745417536,
      lng: -72.53516882047978,
    },
    {
      comuna: "Trehuaco",
      region: "Ñuble",
      name: "Plaza de Armas, Trehuaco",
      addres: "Plaza de Armas, Trehuaco",
      image: PlaceholderImage,
      lat: -36.43094550504296,
      lng: -72.6657676622225,
    },
    {
      comuna: "Cobquecura",
      region: "Ñuble",
      name: "Plaza de Armas Cobquecura",
      addres: "Plaza de Armas de Cobquecura",
      image: PlaceholderImage,
      lat: -36.13173853765868,
      lng: -72.79169558760867,
    },
    {
      comuna: "Portezuelo",
      region: "Ñuble",
      name: "Plaza de Armas Portezuelo",
      addres: "Plaza de Armas Portezuelo",
      image: PlaceholderImage,
      lat: -36.527463200584414,
      lng: -72.42863084194914,
    },
    {
      comuna: "San Fabián",
      region: "Ñuble",
      name: "Plaza de Armas",
      addres: "Plaza de Armas",
      image: PlaceholderImage,
      lat: -36.556505206775235,
      lng: -71.5499240848086,
    },
    {
      comuna: "San Nicolás",
      region: "Ñuble",
      name: "Plaza de los Niños, San Nicolás",
      addres: "Plaza de los Niños, San Nicolás",
      image: PlaceholderImage,
      lat: -36.50400812872065,
      lng: -72.21292602301556,
    },
    {
      comuna: "San Nicolás",
      region: "Ñuble",
      name: "Frente a CESFAM,  La Talavera esquina Arturo Prat",
      addres: "Frente a CESFAM,  La Talavera esquina Arturo Prat",
      image: PlaceholderImage,
      lat: -36.4988083992664,
      lng: -72.21288146620304,
    },
    {
      comuna: "San Nicolás",
      region: "Ñuble",
      name: "Puente Ñuble (costado sede villa San Nicolas)",
      addres: "Puente Ñuble (costado sede villa San Nicolas)",
      image: PlaceholderImage,
      lat: -36.152665193814066,
      lng: -72.09805245523276,
    },
    {
      comuna: "San Carlos",
      region: "Ñuble",
      name: "Plaza de Armas",
      addres: "Plaza de Armas",
      image: PlaceholderImage,
      lat: -36.424816747316136,
      lng: -71.95821164909266,
    },
    {
      comuna: "Ñiquén",
      region: "Ñuble",
      name: "Municipalidad, Estado 188 Ñiquen",
      addres: "Municipalidad, Estado 188 Ñiquen",
      image: PlaceholderImage,
      lat: -36.28170679720777,
      lng: -71.81497012481111,
    },
    {
      comuna: "Coihueco",
      region: "Ñuble",
      name: "Municipalidad",
      addres: "Arturo Prat 1675, Coihueco, Ñuble",
      image: PlaceholderImage,
      lat: -36.6279767281787,
      lng: -71.83122693379215,
    },
    {
      comuna: "CHILLÁN",
      region: "Ñuble",
      name: "Estadio Anfa - Chillán",
      addres:
        "Estadio Anfa Chillán ubicado en calle Turquesa esq. San Ignacio s/n",
      image: PlaceholderImage,
      lat: -36.63326222019848,
      lng: -72.09022334838738,
    },
    {
      comuna: "Chillan",
      region: "Ñuble",
      name: "PUNTO LIMPIO MUNICIPAL",
      addres: "Prolongación Arturo Prat 153 de Chillán",
      image: PlaceholderImage,
      lat: -36.60830501157882,
      lng: -72.11562633173683,
    },
    {
      comuna: "CONCEPCION",
      region: "Biobío",
      name: "PL SODIMAC CONCEPCION",
      addres: "Avenida Los Carrera Poniente 301",
      image: PlaceholderImage,
      lat: -36.829802441058,
      lng: -73.06380595962345,
    },
    {
      comuna: "CONCEPCION",
      region: "Biobío",
      name: "Instituto Teletón Concepción",
      addres: "Los Acacios 1656, Villa San Pedro",
      image: PlaceholderImage,
      lat: -36.84248623288959,
      lng: -73.10733819205177,
    },
    {
      comuna: "CONTULMO",
      region: "Biobío",
      name: "Municipio",
      addres: "Nahuelbuta 109, Contulmo",
      image: PlaceholderImage,
      lat: -38.01528510879183,
      lng: -73.22877872949358,
    },
    {
      comuna: "CURANILAHUE",
      region: "Biobío",
      name: "Municipio",
      addres: "Av. Arturo Prat 801, Curanilahue",
      image: PlaceholderImage,
      lat: -37.47784032377848,
      lng: -73.34367935163256,
    },
    {
      comuna: "ARAUCO",
      region: "Biobío",
      name: "Estadio",
      addres: "Calle Arturo Prat sin número s/n",
      image: PlaceholderImage,
      lat: -37.24638372807959,
      lng: -73.33731405464512,
    },
    {
      comuna: "TALCAHUANO",
      region: "Biobío",
      name: "Plaza de Armas",
      addres: "Anibal Pinto con San Martín",
      image: PlaceholderImage,
      lat: -36.71390702008125,
      lng: -73.11441995057604,
    },
    {
      comuna: "CORONEL",
      region: "Biobío",
      name: "PUNTO CENTRO COMUNITARIO ESCUADRÓN",
      addres: "Avenida Federico Schwager S/N, Población Pocuro Escuadrón",
      image: PlaceholderImage,
      lat: -36.974181089561164,
      lng: -73.14049341580825,
    },
    {
      comuna: "CORONEL",
      region: "Biobío",
      name: "PUNTO PATINODROMO MUNICIPAL",
      addres: "Calle Bernardo Hurault S/N, Población Lagunillas Norte",
      image: PlaceholderImage,
      lat: -36.97408225759429,
      lng: -73.1589315138786,
    },
    {
      comuna: "CORONEL",
      region: "Biobío",
      name: "PUNTO ESTADIO JOSE OLIVA",
      addres: "Esquina Avenida Manuel Montt con Calle Los Chiflones",
      image: PlaceholderImage,
      lat: -36.99898218335834,
      lng: -73.16104568070145,
    },
    {
      comuna: "TOMÉ",
      region: "Biobío",
      name: "Punto de separacion Muelle Hinrrissen ",
      addres: "A Latorre 1260, Tome, Tomé, Bío Bío",
      image: PlaceholderImage,
      lat: -36.62535056918577,
      lng: -72.95669602842437,
    },
    {
      comuna: "TOMÉ",
      region: "Biobío",
      name: "Punto de Separación Cerro San Juan ",
      addres: "Werner Frente a cementerio 1",
      image: PlaceholderImage,
      lat: -36.60881153733442,
      lng: -72.96596650313752,
    },
    {
      comuna: "TOMÉ",
      region: "Biobío",
      name: "Punto de Separación Tomé Alto ",
      addres: "Piloto Pardo, frentea Sede 18 de septiemrbe ",
      image: PlaceholderImage,
      lat: -36.60650581616621,
      lng: -72.96327284130514,
    },
    {
      comuna: "TOMÉ",
      region: "Biobío",
      name: "Punto de separación Ismasle Valdes ",
      addres: "Ismael Valdés, Dichato ",
      image: PlaceholderImage,
      lat: -36.54868751618667,
      lng: -72.93620243588316,
    },
    {
      comuna: "TOMÉ",
      region: "Biobío",
      name: "Delegación Municipal Rafael",
      addres: "Rafael , Delegación Municipal Rafael, Calle Ohiggins comn ",
      image: PlaceholderImage,
      lat: -36.63008328985386,
      lng: -72.79404529421697,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Altos de Chiguayante",
      addres: "Pasaje 19 con Calle D",
      image: PlaceholderImage,
      lat: -36.92073177974624,
      lng: -73.03373803570457,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Copa 1",
      addres: "Emilio Rojas. Esq. Matte",
      image: PlaceholderImage,
      lat: -36.92111242998159,
      lng: -73.02976719579252,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Cochrane (Ex Liceo P.)",
      addres: "Cochrane, esq. La Marina",
      image: PlaceholderImage,
      lat: -36.922356663527495,
      lng: -73.0304864757775,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Punto L. Santa Isabel",
      addres: "Cochrane con Manuel Rodriguez",
      image: PlaceholderImage,
      lat: -36.92129572658685,
      lng: -73.02701189482985,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Chiguay 1",
      addres: "Chiguay, esq. La Marina",
      image: PlaceholderImage,
      lat: -36.925844665095966,
      lng: -73.02933851995844,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Las Condes",
      addres: "Las Condes, esq. Manuel Rodriguez",
      image: PlaceholderImage,
      lat: -36.92640837498473,
      lng: -73.02491771810479,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "La Marina",
      addres: "La Marina / Las Condes",
      image: PlaceholderImage,
      lat: -36.92858099104732,
      lng: -73.02971247324454,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Los Héroes",
      addres: "Los héroes / La Marina",
      image: PlaceholderImage,
      lat: -36.93076316692558,
      lng: -73.02886431792913,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Pinares 3 Cesfam",
      addres: "Pinares / La Marina",
      image: PlaceholderImage,
      lat: -36.93362983141205,
      lng: -73.02776104591977,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Pinares Plaza",
      addres: "Pinares/ Blindado Blanco",
      image: PlaceholderImage,
      lat: -36.93203334927353,
      lng: -73.02368897731905,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Punto Limpio Unimarc",
      addres: "Libertad con Manuel Rodriguez",
      image: PlaceholderImage,
      lat: -36.934008306966454,
      lng: -73.0218581930083,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Altumalal",
      addres: "Libertad 492",
      image: PlaceholderImage,
      lat: -36.93411066213223,
      lng: -73.0235424452819,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "El Piñon",
      addres: "Avda. 101 con Progreso",
      image: PlaceholderImage,
      lat: -36.93797601650043,
      lng: -73.027476318104,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "JJVV los Pensamientos",
      addres: "Calle 5, Los Pensamientos",
      image: PlaceholderImage,
      lat: -36.950764021731985,
      lng: -73.01764202371507,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Manquimavida1",
      addres: "Manquimavida, esq. Pasaje 2 al Rio",
      image: PlaceholderImage,
      lat: -36.943505646174174,
      lng: -73.02604663498641,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Punto Limpio Acuenta",
      addres: "Manuel Rodriguez/ Coquimbo",
      image: PlaceholderImage,
      lat: -36.944895251966564,
      lng: -73.01711264158993,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Ciudad Alpina",
      addres: "Esperanza, pasaje 1, esq. Calle 1",
      image: PlaceholderImage,
      lat: -36.94677807339388,
      lng: -73.01833876547808,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Villa Manquimavida",
      addres: "Calle 2, esq. Pasaje 2,  V. Manquimavida",
      image: PlaceholderImage,
      lat: -36.94672210412684,
      lng: -73.02028941544944,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Los Aromos",
      addres: "Los Aromos / Los Radales",
      image: PlaceholderImage,
      lat: -36.94560948328283,
      lng: -73.0263012211869,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Villa Alonso de Ercilla",
      addres: "Porvenir / pasaje 9",
      image: PlaceholderImage,
      lat: -36.948840219000544,
      lng: -73.01829044242106,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Los Pinos",
      addres: "Yugoeslavia, esq. Calle 3",
      image: PlaceholderImage,
      lat: -36.95046848066714,
      lng: -73.01880794325251,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Villa Ines Frodden",
      addres: "Calle los Pinos / Graciela Lacoste",
      image: PlaceholderImage,
      lat: -36.9507020640142,
      lng: -73.0159843717018,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "JJVV Villa Leonera",
      addres: "Hamburgo con Italia",
      image: PlaceholderImage,
      lat: -36.9530922181726,
      lng: -73.01632759330512,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Villa La Union",
      addres: "Pasaje 1, esq. Manuel Rodríguez",
      image: PlaceholderImage,
      lat: -36.956318351821956,
      lng: -73.01286942855218,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Villa Los Presidentes",
      addres: "Arturo Alessandri / Eduardo Frei",
      image: PlaceholderImage,
      lat: -36.94944916886514,
      lng: -73.01350778241503,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Plaza del Sol1",
      addres: "Esperanza, esq. Calle A",
      image: PlaceholderImage,
      lat: -36.944569852229606,
      lng: -73.01310924879346,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Coquimbo",
      addres: "Coquimbo, esq. Avd. 105",
      image: PlaceholderImage,
      lat: -36.94277124919381,
      lng: -73.01372338926743,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Manquimavida3",
      addres: "Manquimavida- Avda. 105",
      image: PlaceholderImage,
      lat: -36.93955430659105,
      lng: -73.01539159667291,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Bio Bio",
      addres: "Bio Bio con Avda. 105",
      image: PlaceholderImage,
      lat: -36.93816073167084,
      lng: -73.02273514196773,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Punto Limpio Easy",
      addres: "Estacionamiento Easy",
      image: PlaceholderImage,
      lat: -36.93039180455527,
      lng: -73.02145599112197,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Pinares1, La Estrella",
      addres: "Pinares / Linea Férrea",
      image: PlaceholderImage,
      lat: -36.929549608726965,
      lng: -73.01805352528945,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Pedro Medina",
      addres: "Manuel R. esq. P. Medina",
      image: PlaceholderImage,
      lat: -36.926120202446526,
      lng: -73.02453704035271,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Barrio Comercial",
      addres: "O´Higgins, esq. Angamos",
      image: PlaceholderImage,
      lat: -36.9137665012628,
      lng: -73.02915461867352,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "El Pantano",
      addres: "El Pantano con E. Ramirez",
      image: PlaceholderImage,
      lat: -36.911329285861406,
      lng: -73.0211683546145,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Gimnasio",
      addres: "Pedro Aguirre Cerda, lado Gimnasio",
      image: PlaceholderImage,
      lat: -36.910129044418724,
      lng: -73.03001831810586,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Plaza de Armas 1",
      addres: "Leinenweber, esq. Plaza",
      image: PlaceholderImage,
      lat: -36.90721523339513,
      lng: -73.02917133664259,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Poblacion Valdes",
      addres: "O´Higgins, Plazoleta Tomas Solis",
      image: PlaceholderImage,
      lat: -36.90440004941256,
      lng: -73.03006161146168,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Alhambra",
      addres: "Avda.Alhambra, lado colegio Arabe",
      image: PlaceholderImage,
      lat: -36.90363082288957,
      lng: -73.02679891015221,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Colon",
      addres: "Colon, esq. Calle 1",
      image: PlaceholderImage,
      lat: -36.90193263381018,
      lng: -73.0248560046151,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Schaub",
      addres: "Wihelm Schaub, esq. Los Olivillos",
      image: PlaceholderImage,
      lat: -36.894707410272815,
      lng: -73.03062154323581,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Strip Villuco 1",
      addres: "Ignacio Verdugo esq: Diego Urrutia",
      image: PlaceholderImage,
      lat: -36.922073393171736,
      lng: -73.02449493618568,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Alto Lemu",
      addres: "Ed. Alto Lemu, Avda Lonco Parque",
      image: PlaceholderImage,
      lat: -36.932960266313444,
      lng: -73.03195653997946,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Lonco Parque",
      addres: "Cinco Poniente S/N , poste N°15",
      image: PlaceholderImage,
      lat: -36.86881850273644,
      lng: -73.03636053609243,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Binimellis",
      addres: "Binimellis, esq. Eyzaguirre",
      image: PlaceholderImage,
      lat: -36.86715146366343,
      lng: -73.03984707577712,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Lonco Norte",
      addres: "Sotomayor, esq. Fco. Encina",
      image: PlaceholderImage,
      lat: -36.86503435415754,
      lng: -73.04191739112629,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Punto Limpio 8 Oriente",
      addres: "Strip Center 8 Oriente",
      image: PlaceholderImage,
      lat: -36.90701504669938,
      lng: -73.03155825657527,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "La Ribera",
      addres: "Central, esq.  Calle 2",
      image: PlaceholderImage,
      lat: -36.90707834084907,
      lng: -73.0328788011543,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Plaza los Bomberos",
      addres: "O´Higgins, esq. Cochrane",
      image: PlaceholderImage,
      lat: -36.92090292176526,
      lng: -73.02470182483945,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Las Vertientes",
      addres: "San Martin, esq. Central",
      image: PlaceholderImage,
      lat: -36.92544927358227,
      lng: -73.02051323344976,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Valle La Piedra 2",
      addres: "San Martin, esq. El Castaño",
      image: PlaceholderImage,
      lat: -36.93199083529943,
      lng: -73.01411951810437,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Villa Pro. Y Comercio",
      addres: "Calle C s/n",
      image: PlaceholderImage,
      lat: -36.93752013493823,
      lng: -73.00992023461444,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Villa Santo Domingo",
      addres: "O´Higgins, esq. Calle 2",
      image: PlaceholderImage,
      lat: -36.94262585807972,
      lng: -73.0098930059604,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Villa Pradera",
      addres: "Calle 1, esq. Calle 2",
      image: PlaceholderImage,
      lat: -36.94143297219918,
      lng: -73.02310520461249,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "CENTRO DE RECICLAJE",
      addres: "Herrera 100, sector Papen",
      image: PlaceholderImage,
      lat: -36.92149081456671,
      lng: -73.02073222608102,
    },
    {
      comuna: "CHIGUAYANTE",
      region: "Biobío",
      name: "Casa de la Cultura",
      addres: "Orozimbo Barbosa 294",
      image: PlaceholderImage,
      lat: -36.91817908401224,
      lng: -73.03440289112272,
    },
    {
      comuna: "CONCEPCION",
      region: "Biobío",
      name: "CENTRO DE ACOPIO MUNICIPAL",
      addres: "Diego de Almagro  540, Lorenzo Arenas ",
      image: PlaceholderImage,
      lat: -36.807823361935625,
      lng: -73.06709921996631,
    },
    {
      comuna: "CONCEPCION",
      region: "Biobío",
      name: "DELEGACION MUNICIPAL",
      addres: "Manuel Gutierrez  1745, Barrio Norte ",
      image: PlaceholderImage,
      lat: -36.804692829921095,
      lng: -73.04758186229402,
    },
    {
      comuna: "CONCEPCION",
      region: "Biobío",
      name: "CENTRO PARTICIPACION CIUDADANA",
      addres: "Pedro de Oña con Marina de Chile -   Lorenzo Arenas S/N ",
      image: PlaceholderImage,
      lat: -36.80735312179607,
      lng: -73.07725723531125,
    },
    {
      comuna: "CONCEPCION",
      region: "Biobío",
      name: "CENTRO DE CREACION CONCEPCION - C3",
      addres: "Juan Martínez de Rozas 520 ",
      image: PlaceholderImage,
      lat: -36.82223318807187,
      lng: -73.05510034694797,
    },
    {
      comuna: "Penco",
      region: "Biobío",
      name: "Plaza Los Conquistadores",
      addres: 'O"higgins N° 500',
      image: PlaceholderImage,
      lat: -36.738599865869,
      lng: -72.99379804301176,
    },
    {
      comuna: "Hualpén",
      region: "Biobío",
      name: "Municipio",
      addres:
        "Calle Patria Nueva Nº 1035 (Ex Escuela Perla del Bio Bio) Hualpén",
      image: PlaceholderImage,
      lat: -36.791842514331,
      lng: -73.09916686072043,
    },
    {
      comuna: "Hualpén",
      region: "Biobío",
      name: "Lenga",
      addres: "Costanera Lenga",
      image: PlaceholderImage,
      lat: -36.816360374196215,
      lng: -73.17088211561368,
    },
    {
      comuna: "Hualpén",
      region: "Biobío",
      name: "DIMAE",
      addres: "Bélgica #1563",
      image: PlaceholderImage,
      lat: -36.78222562137984,
      lng: -73.10617230399846,
    },
    {
      comuna: "Hualpén",
      region: "Biobío",
      name: "La Floresta",
      addres: "Avenida uno #2603",
      image: PlaceholderImage,
      lat: -36.79203450342651,
      lng: -73.11461523168498,
    },
    {
      comuna: "Hualpén",
      region: "Biobío",
      name: "Parque Central",
      addres: "Los Zorzales #8299",
      image: PlaceholderImage,
      lat: -36.79573467043982,
      lng: -73.08202481819117,
    },
    {
      comuna: "Florida",
      region: "Biobío",
      name: "Plaza",
      addres: "Ignacio Serrano N° 700",
      image: PlaceholderImage,
      lat: -36.8248909267225,
      lng: -72.65998398935464,
    },
    {
      comuna: "Florida",
      region: "Biobío",
      name: "Estadio",
      addres: "Ignacio Serrano N° 300",
      image: PlaceholderImage,
      lat: -36.82462652124942,
      lng: -72.6683491451771,
    },
    {
      comuna: "Florida",
      region: "Biobío",
      name: "Altura Escuela F634",
      addres: "Carlos Condell  N° 610",
      image: PlaceholderImage,
      lat: -36.82434935318346,
      lng: -72.65090811679664,
    },
    {
      comuna: "Florida",
      region: "Biobío",
      name: "Pob. Villa Esperanza",
      addres: "San José de la Dehesa N° 147",
      image: PlaceholderImage,
      lat: -36.8242596481494,
      lng: -72.65081708465651,
    },
    {
      comuna: "Florida",
      region: "Biobío",
      name: "Pob.Puchacay",
      addres: "Las Hortensia N° 1128",
      image: PlaceholderImage,
      lat: -36.81819415652179,
      lng: -72.65066562944708,
    },
    {
      comuna: "Florida",
      region: "Biobío",
      name: "San Lorenzo",
      addres: "Las Hortencia N°1410",
      image: PlaceholderImage,
      lat: -36.81826822287536,
      lng: -72.65057291534347,
    },
    {
      comuna: "Florida",
      region: "Biobío",
      name: "Plazoleta de Tapihue",
      addres: "El Quillay s/n ",
      image: PlaceholderImage,
      lat: -36.82668400547578,
      lng: -72.67877781349239,
    },
    {
      comuna: "Florida",
      region: "Biobío",
      name: "Municipio",
      addres: "Prat 675, Florida",
      image: PlaceholderImage,
      lat: -36.82375528118186,
      lng: -72.66066023115803,
    },
    {
      comuna: "Santa Juana",
      region: "Biobío",
      name: "punto limpio 5 esquinas",
      addres: "interseccion calle valdivia con calle baquedano ",
      image: PlaceholderImage,
      lat: -37.17202277698822,
      lng: -72.95017342493364,
    },
    {
      comuna: "Santa Juana",
      region: "Biobío",
      name: "punto limpio rayito de luz",
      addres: "interseccion calle pedro aguirre cerda con calle valdivia ",
      image: PlaceholderImage,
      lat: -37.17386905483102,
      lng: -72.94512343044136,
    },
    {
      comuna: "Santa Juana",
      region: "Biobío",
      name: "punto limpio movil plaza de armas ",
      addres: "Plaza de armas Santa Juana ",
      image: PlaceholderImage,
      lat: -37.17493680769966,
      lng: -72.93746774075358,
    },
    {
      comuna: "SAN PEDRO DE LA PAZ",
      region: "Biobío",
      name: "Municipio",
      addres: "Los Acacios 43 Villa San Pedro",
      image: PlaceholderImage,
      lat: -36.84141808831863,
      lng: -73.1035386801182,
    },
    {
      comuna: "NACIMIENTO",
      region: "Biobío",
      name: "Nacimiento",
      addres: "Freire 614",
      image: PlaceholderImage,
      lat: -37.50216663569017,
      lng: -72.67429087348306,
    },
    {
      comuna: "SAN ROSENDO",
      region: "Biobío",
      name: "Municipalidad de San Rosendo",
      addres: "Ibieta 225",
      image: PlaceholderImage,
      lat: -37.26383351104168,
      lng: -72.72598686817341,
    },
    {
      comuna: "Santa Bárbara",
      region: "Biobío",
      name: "Sector Costanera ",
      addres: "Sector Costanera ",
      image: PlaceholderImage,
      lat: -37.6713158,
      lng: -72.0921695,
    },
    {
      comuna: "Santa Bárbara",
      region: "Biobío",
      name: "los poetas ",
      addres: "Carlos Pezoa, Santa Bárbara, Bío Bío",
      image: PlaceholderImage,
      lat: -37.662437,
      lng: -72.020569,
    },
    {
      comuna: "Santa Bárbara",
      region: "Biobío",
      name: "villa araucaria ",
      addres: "villa araucaria ",
      image: PlaceholderImage,
      lat: -37.662887,
      lng: -72.025709,
    },
    {
      comuna: "Santa Bárbara",
      region: "Biobío",
      name: "Carlos Hibañez del campo ",
      addres: "Carlos Hibañez del campo ",
      image: PlaceholderImage,
      lat: -37.669639,
      lng: -72.01302,
    },
    {
      comuna: "Santa Bárbara",
      region: "Biobío",
      name: "Jasinto Sufan, erazmo zabala ",
      addres: "Jasinto Sufan, erazmo zabala ",
      image: PlaceholderImage,
      lat: -37.662851,
      lng: -72.019386,
    },
    {
      comuna: "Santa Bárbara",
      region: "Biobío",
      name: "Liceo CAS  ",
      addres: "Liceo CAS  ",
      image: PlaceholderImage,
      lat: -37.6656617,
      lng: -72.0326965,
    },
    {
      comuna: "Santa Bárbara",
      region: "Biobío",
      name: "plaza de armas ",
      addres: "Las Rosas 50, 4510000 Santa Bárbara, Bío Bío",
      image: PlaceholderImage,
      lat: -37.67041183911117,
      lng: -72.02142695987311,
    },
    {
      comuna: "Antuco",
      region: "Biobío",
      name: "Plaza de Armas",
      addres: "Q-45 64, Antuco, Bío Bío",
      image: PlaceholderImage,
      lat: -37.32984419315732,
      lng: -71.67934903774172,
    },
    {
      comuna: "Antuco",
      region: "Biobío",
      name: "Villa Los Quillayes",
      addres: "Villa Los Quillayes",
      image: PlaceholderImage,
      lat: -37.330006226688845,
      lng: -71.66938847982517,
    },
    {
      comuna: "Antuco",
      region: "Biobío",
      name: "Villa Jardín",
      addres: "Villa Jardín",
      image: PlaceholderImage,
      lat: -37.32989348115648,
      lng: -71.66561930273294,
    },
    {
      comuna: "Antuco",
      region: "Biobío",
      name: "Estadio Municipal",
      addres: "Antuco",
      image: PlaceholderImage,
      lat: -37.33128870443566,
      lng: -71.67593719126147,
    },
    {
      comuna: "Antuco",
      region: "Biobío",
      name: "Villa Los Ríos",
      addres: "Villa Los Ríos",
      image: PlaceholderImage,
      lat: -37.32510713919614,
      lng: -71.66977584876805,
    },
    {
      comuna: "Antuco",
      region: "Biobío",
      name: "Santa Rosa esq Ohiggins",
      addres: "Santa Rosa esq Ohiggins",
      image: PlaceholderImage,
      lat: -37.32958567387987,
      lng: -71.67111110458659,
    },
    {
      comuna: "Antuco",
      region: "Biobío",
      name: "Población Baeza",
      addres: "Población Baeza",
      image: PlaceholderImage,
      lat: -37.32566686149241,
      lng: -71.67481184691445,
    },
    {
      comuna: "Antuco",
      region: "Biobío",
      name: "Población Valle del Laja",
      addres: "Sta. Rosa 12, Antuco, Bío Bío",
      image: PlaceholderImage,
      lat: -37.32644437209389,
      lng: -71.67148293342309,
    },
    {
      comuna: "Antuco",
      region: "Biobío",
      name: "Villa Las Rosas",
      addres: "Villa Las Rosas",
      image: PlaceholderImage,
      lat: -37.328457509315065,
      lng: -71.65258583527661,
    },
    {
      comuna: "Antuco",
      region: "Biobío",
      name: "Villa Los Canelos",
      addres: "Villa Los Canelos",
      image: PlaceholderImage,
      lat: -37.35888102693403,
      lng: -71.52713674960746,
    },
    {
      comuna: "Antuco",
      region: "Biobío",
      name: "Mirrihue Alto",
      addres: "Mirrihue Alto",
      image: PlaceholderImage,
      lat: -37.3375469072598,
      lng: -71.73571854173633,
    },
    {
      comuna: "Antuco",
      region: "Biobío",
      name: "Abanico",
      addres: "Abanico",
      image: PlaceholderImage,
      lat: -37.36900946372565,
      lng: -71.48832037872855,
    },
    {
      comuna: "Antuco",
      region: "Biobío",
      name: "Estero Huinganes Alto Rucue",
      addres: "Estero Huinganes Alto Rucue",
      image: PlaceholderImage,
      lat: -37.38258327207893,
      lng: -71.6974488910918,
    },
    {
      comuna: "LAJA",
      region: "Biobío",
      name: "Municipio",
      addres: "Balmaceda 292",
      image: PlaceholderImage,
      lat: -37.280100959153465,
      lng: -72.71533090517956,
    },
    {
      comuna: "Los Ángeles",
      region: "Biobío",
      name: "PL POLIDEPORTIVO",
      addres: "MARCONI CON GABRIELA MISTRAL",
      image: PlaceholderImage,
      lat: -37.47430917567229,
      lng: -72.33628986374156,
    },
    {
      comuna: "Los Ángeles",
      region: "Biobío",
      name: "PL EL LABRIEGO, VILLA LAS TRANQUERAS",
      addres: "CALLE EL LABRIEGO, LAS TRANQUERAS 5, VILLA LAS TRANQUERAS",
      image: PlaceholderImage,
      lat: -37.46093787477107,
      lng: -72.38114471349694,
    },
    {
      comuna: "Los Ángeles",
      region: "Biobío",
      name: "PL BAQUEDANO",
      addres: "BAQUEDANO / AGUSTINOS (SEDE JJVV #33 VILLA GALILEA)",
      image: PlaceholderImage,
      lat: -37.4760481789324,
      lng: -72.37515021691593,
    },
    {
      comuna: "Los Ángeles",
      region: "Biobío",
      name: "PL MARIA AUXILIADORA",
      addres: "MARIA AUXILIADORA / JOSÉ SANTOS OSSA (JARDINES DE LA REPÚBLICA)",
      image: PlaceholderImage,
      lat: -37.4822382901245,
      lng: -72.34362061380553,
    },
    {
      comuna: "Los Ángeles",
      region: "Biobío",
      name: "PL NUEVA ESPERANZA",
      addres:
        "CALLE NUEVA ESPERANZA, BLOCK 1 AL 4, CONDOMINIOS MARIA AUXILIADORA Y HERMANOS CARRERA EN EL SECTOR VILLA FILADELFIA",
      image: PlaceholderImage,
      lat: -37.497152424065746,
      lng: -72.32862683783847,
    },
    {
      comuna: "Los Ángeles",
      region: "Biobío",
      name: "PL ALTOS DEL RETIRO",
      addres: "SACRAMENTO /MISIONEROS (ALTOS DEL RETIRO, SECTOR GALILEA)",
      image: PlaceholderImage,
      lat: -37.479686514549776,
      lng: -72.37708715443063,
    },
    {
      comuna: "Los Ángeles",
      region: "Biobío",
      name: "PL COSTANERA QUILQUE NORTE",
      addres: "COSTANERA QUILQUE NORTE/NAHUELBUTA (PARQUES NACIONALES)",
      image: PlaceholderImage,
      lat: -37.461806033609506,
      lng: -72.33702706496037,
    },
    {
      comuna: "Los Ángeles",
      region: "Biobío",
      name: "NIEVES VÁSQUEZ",
      addres: "AVDA. NIEVES VÁSQUEZ/LUXEMBURGO (PARQUE SORVICENTA)",
      image: PlaceholderImage,
      lat: -37.45008103889741,
      lng: -72.33266919814574,
    },
    {
      comuna: "Los Ángeles",
      region: "Biobío",
      name: "PL. CESFAM NUEVO HORIZONTE",
      addres:
        "LAS TORCAZAS/AGUAS CALIENTES (CESFAM NUEVO HORIZONTE, VILLA LOS PROFESORES)",
      image: PlaceholderImage,
      lat: -37.467235789434966,
      lng: -72.38322291400017,
    },
    {
      comuna: "Los Ángeles",
      region: "Biobío",
      name: "PL. DELEGACIÓN SALTOS DEL LAJA",
      addres: "AVENIDA EL SALTO DELEGACIÓN MUNICIPAL SALTOS DEL LAJA",
      image: PlaceholderImage,
      lat: -37.219775735364074,
      lng: -72.376698046244,
    },
    {
      comuna: "Los Ángeles",
      region: "Biobío",
      name: "PL. DELEGACIÓN SANTA FE",
      addres: "O´HIGGINS S/N, DELEGACIÓN MUNICIPAL SANTA FE",
      image: PlaceholderImage,
      lat: -37.46604116862138,
      lng: -72.5797466914991,
    },
    {
      comuna: "Los Ángeles",
      region: "Biobío",
      name: "PL. SAN CARLOS PUREN",
      addres:
        "AVENIDA BIOBÍO S/N RUTA 5 SUR, ENTRE CALLE LAUTARO Y CAUPOLICÁN, EXTERIOR DE JUNTA DE VECINOS",
      image: PlaceholderImage,
      lat: -37.595567400555524,
      lng: -72.27822745249934,
    },
    {
      comuna: "Los Ángeles",
      region: "Biobío",
      name: "PL. PORTAL MANSO DE VELASCO",
      addres: "COSTANERA PAILLIHUE/AVDA. ORIENTE, PORTAL MANSO DE VELASCO",
      image: PlaceholderImage,
      lat: -37.47659507287355,
      lng: -72.32892401727857,
    },
    {
      comuna: "CABRERO",
      region: "Biobío",
      name: "Municipio Cabrero",
      addres: "Las delicias 355, Cabrero",
      image: PlaceholderImage,
      lat: -37.03752460036669,
      lng: -72.40627506734347,
    },
    {
      comuna: "NEGRETE",
      region: "Biobío",
      name: "Municipio Negrete",
      addres: "Alberto Möller #293",
      image: PlaceholderImage,
      lat: -37.584913393618855,
      lng: -72.52847648035137,
    },
    {
      comuna: "MULCHÉN",
      region: "Biobío",
      name: "Municipio Mulchen 1",
      addres: "Anibal Pinto 495, Mulchén",
      image: PlaceholderImage,
      lat: -37.72077925888896,
      lng: -72.2442587052416,
    },
    {
      comuna: "MULCHÉN",
      region: "Biobío",
      name: "Municipio Mulchen 2",
      addres: "Balmaceda, 4531093 Mulchen, Negrete, Bío Bío",
      image: PlaceholderImage,
      lat: -37.71262177356074,
      lng: -72.23676886579996,
    },
    {
      comuna: "MULCHÉN",
      region: "Biobío",
      name: "Municipio Mulchen 3",
      addres: "Parque Las Peñas, Mulchen",
      image: PlaceholderImage,
      lat: -37.71184147051262,
      lng: -72.246002022125,
    },
    {
      comuna: "MULCHÉN",
      region: "Biobío",
      name: "Municipio Mulchen 4",
      addres: "Parque Quinta Venecia",
      image: PlaceholderImage,
      lat: -37.71393602264473,
      lng: -72.24925006320517,
    },
    {
      comuna: "MULCHÉN",
      region: "Biobío",
      name: "Municipio Mulchen 5",
      addres: "Salvo 795, Mulchen",
      image: PlaceholderImage,
      lat: -37.71736555284256,
      lng: -72.23641266894676,
    },
    {
      comuna: "MULCHÉN",
      region: "Biobío",
      name: "Municipio Mulchen 6",
      addres: "Anibal Pinto & Av. Matta",
      image: PlaceholderImage,
      lat: -37.71920353900922,
      lng: -72.24960564333006,
    },
    {
      comuna: "MULCHÉN",
      region: "Biobío",
      name: "Municipio Mulchen 7",
      addres: "Barros Arana 320",
      image: PlaceholderImage,
      lat: -37.723679536628005,
      lng: -72.25371399748678,
    },
    {
      comuna: "MULCHÉN",
      region: "Biobío",
      name: "Municipio Mulchen 8",
      addres: "Mulchén - Alto Caledonia - Estero Pehuenco",
      image: PlaceholderImage,
      lat: -37.7282311580795,
      lng: -72.23217879759287,
    },
    {
      comuna: "MULCHÉN",
      region: "Biobío",
      name: "Municipio Mulchen 9",
      addres: "Jose Miguel Carrera & Condell",
      image: PlaceholderImage,
      lat: -37.7266458861367,
      lng: -72.23829960546544,
    },
    {
      comuna: "MULCHÉN",
      region: "Biobío",
      name: "Municipio Mulchen 10",
      addres: "Cancha Sintetica Los Lleuques, Mulchén",
      image: PlaceholderImage,
      lat: -37.73261299867094,
      lng: -72.23826233198362,
    },
    {
      comuna: "TEMUCO",
      region: "La Araucanía",
      name: "PL SODIMAC TEMUCO",
      addres: "Caupolicán 0457",
      image: PlaceholderImage,
      lat: -38.74374524802385,
      lng: -72.6095760611391,
    },
    {
      comuna: "TEMUCO",
      region: "La Araucanía",
      name: "PL SODIMAC TEMUCO II",
      addres: "Avenida Las Encinas 02457",
      image: PlaceholderImage,
      lat: -38.74178118332182,
      lng: -72.64013222970769,
    },
    {
      comuna: "TEMUCO",
      region: "La Araucanía",
      name: "Instituto Teletón Temuco",
      addres: "General Aldunate 0390",
      image: PlaceholderImage,
      lat: -38.72986802506413,
      lng: -72.58601176136784,
    },
    {
      comuna: "Teodoro Schmidt",
      region: "La Araucanía",
      name: "Aparcadero Municipal Teodoro Schmidt",
      addres: "Calle Ex Línea Férrea s/n, Teodoro Schmidt",
      image: PlaceholderImage,
      lat: -38.992733,
      lng: -73.08841,
    },
    {
      comuna: "Villarrica",
      region: "La Araucanía",
      name: "Atrás de Terminal Buses",
      addres: "Luis Cruz Martinéz 380, Villarrica, Araucanía",
      image: PlaceholderImage,
      lat: -39.280695,
      lng: -72.313331,
    },
    {
      comuna: "Angol",
      region: "La Araucanía",
      name: "Banco de Chile",
      addres: "Lautaro 02",
      image: PlaceholderImage,
      lat: -37.79777457856943,
      lng: -72.70580056751183,
    },
    {
      comuna: "Vilcún",
      region: "La Araucanía",
      name: "Centro de reciclaje Municipal",
      addres:
        "Calle Freire altura N°250 (acopio al lado de oficina Chile Atiende)",
      image: PlaceholderImage,
      lat: -38.67025680087815,
      lng: -72.22745600845158,
    },
    {
      comuna: "Nueva Imperial",
      region: "La Araucanía",
      name: "Ecojaula Borde Río",
      addres: "Calle Castellón 065",
      image: PlaceholderImage,
      lat: -38.74184,
      lng: -72.95692,
    },
    {
      comuna: "Nueva Imperial",
      region: "La Araucanía",
      name: "Ecojaula parque thiers",
      addres: "Avenida Thiers",
      image: PlaceholderImage,
      lat: -38.75151,
      lng: -72.94216,
    },
    {
      comuna: "Nueva Imperial",
      region: "La Araucanía",
      name: "Ecojaula plaza huincul",
      addres: "Calle Diego Portales 132",
      image: PlaceholderImage,
      lat: -38.73934,
      lng: -72.95541,
    },
    {
      comuna: "Nueva Imperial",
      region: "La Araucanía",
      name: "Ecojaula plaza Pedro de Valdivia",
      addres: "Balmaceda, esquina prat",
      image: PlaceholderImage,
      lat: -38.7459,
      lng: -72.95275,
    },
    {
      comuna: "Villarrica",
      region: "La Araucanía",
      name: "Estadio Licanray",
      addres: "Urbano Tapia 855, 4930000 Licanray, Villarrica, Araucanía",
      image: PlaceholderImage,
      lat: -39.486167,
      lng: -72.159923,
    },
    {
      comuna: "Villarrica",
      region: "La Araucanía",
      name: "Frontis Municipal",
      addres: "Av. Pedro de Valdivia 810, 4930523 Villarrica, Araucanía",
      image: PlaceholderImage,
      lat: -39.283619,
      lng: -72.226627,
    },
    {
      comuna: "Gorbea",
      region: "La Araucanía",
      name: "Gimnasio Municipal",
      addres: "Andres Bello 485",
      image: PlaceholderImage,
      lat: -39.102664,
      lng: -72.680095,
    },
    {
      comuna: "Freire",
      region: "La Araucanía",
      name: "Plaza las Banderas",
      addres: "Av. Lynch con Av. Juan Schlayer",
      image: PlaceholderImage,
      lat: -38.95537,
      lng: -72.626031,
    },
    {
      comuna: "Cunco",
      region: "La Araucanía",
      name: "Punto Limpio",
      addres: "Calle Alessandri N°398",
      image: PlaceholderImage,
      lat: -38.929932815966446,
      lng: -72.03209070523205,
    },
    {
      comuna: "Angol",
      region: "La Araucanía",
      name: "Punto Limpio",
      addres: "Michigan 2595",
      image: PlaceholderImage,
      lat: -37.811026821396304,
      lng: -72.67431059918091,
    },
    {
      comuna: "Melipeuco",
      region: "La Araucanía",
      name: "Punto limpio ",
      addres: "Aurelio Letelier#80",
      image: PlaceholderImage,
      lat: -38.855636,
      lng: -71.697469,
    },
    {
      comuna: "Saavedra",
      region: "La Araucanía",
      name: "Punto Limpio Saavedra",
      addres: "Chorrillos S/N",
      image: PlaceholderImage,
      lat: -38.794256,
      lng: -73.400135,
    },
    {
      comuna: "Saavedra",
      region: "La Araucanía",
      name: "Punto Verde Dominguez",
      addres: "S-46 S/N",
      image: PlaceholderImage,
      lat: -38.897529,
      lng: -73.255637,
    },
    {
      comuna: "VILCUN",
      region: "La Araucanía",
      name: "Vilcún",
      addres: "S-31, 394 Vilcún",
      image: PlaceholderImage,
      lat: -38.67028877857907,
      lng: -72.22441322579286,
    },
    {
      comuna: "VALDIVIA",
      region: "Los Ríos",
      name: "Instituto Teletón Valdivia ",
      addres: "Av. René Schneider 2631, Valdivia",
      image: PlaceholderImage,
      lat: -39.83633626355903,
      lng: -73.21992273068392,
    },
    {
      comuna: "LAGO RANCO",
      region: "Los Ríos",
      name: "Alucema",
      addres: "Ruta T-761 S/N (Frente Iglesia)",
      image: PlaceholderImage,
      lat: -40.295284,
      lng: -72.595544,
    },
    {
      comuna: "LAGO RANCO",
      region: "Los Ríos",
      name: "Calcurrupue",
      addres: "Ruta T-85 S/N (Calcurrupe Bajo)",
      image: PlaceholderImage,
      lat: -40.232942,
      lng: -72.249799,
    },
    {
      comuna: "LAGO RANCO",
      region: "Los Ríos",
      name: "Calle Santiago",
      addres: "Calle Santiago S/N (Frente Liceo Antonio Varas)",
      image: PlaceholderImage,
      lat: -40.322201,
      lng: -72.479273,
    },
    {
      comuna: "LAGO RANCO",
      region: "Los Ríos",
      name: "Carpa Costanera",
      addres: "Inteersección Calle Ancud/Los Angeles",
      image: PlaceholderImage,
      lat: -40.319951,
      lng: -72.475013,
    },
    {
      comuna: "PANGUIPULLI",
      region: "Los Ríos",
      name: "Centro de acopio de reciclaje",
      addres: "Manuel Rodriguez # 326",
      image: PlaceholderImage,
      lat: -39.64476322662791,
      lng: -72.33073078446141,
    },
    {
      comuna: "LAGO RANCO",
      region: "Los Ríos",
      name: "Costanera",
      addres: "Calle Viña del Mar S/N",
      image: PlaceholderImage,
      lat: -40.320172,
      lng: -72.480861,
    },
    {
      comuna: "LAGO RANCO",
      region: "Los Ríos",
      name: "El arenal",
      addres: "Ruta T-719 S/N (Sede Junta de Vecinos)",
      image: PlaceholderImage,
      lat: -40.264978,
      lng: -72.23753,
    },
    {
      comuna: "MARIQUINA",
      region: "Los Ríos",
      name: "Ex edificio Consistorial",
      addres: "Mariquina #64",
      image: PlaceholderImage,
      lat: -39.538916,
      lng: -72.961981,
    },
    {
      comuna: "LANCO",
      region: "Los Ríos",
      name: "Galpón de Reciclaje",
      addres: "Calle Unión#405",
      image: PlaceholderImage,
      lat: -39.45038835801396,
      lng: -72.77122077567486,
    },
    {
      comuna: "LAGO RANCO",
      region: "Los Ríos",
      name: "Ignao",
      addres: "Ruta T-859 S/N (Mercadito Ignao)",
      image: PlaceholderImage,
      lat: -40.332717,
      lng: -72.557373,
    },
    {
      comuna: "LAGO RANCO",
      region: "Los Ríos",
      name: "Ilihue",
      addres: "Ruta T-85 S/N (Cruce Ancao)",
      image: PlaceholderImage,
      lat: -40.326315,
      lng: -72.402339,
    },
    {
      comuna: "LAGO RANCO",
      region: "Los Ríos",
      name: "Illahuapi",
      addres: "Ruta T-719 S/N (Frente Escuela Rural)",
      image: PlaceholderImage,
      lat: -40.271986,
      lng: -72.311782,
    },
    {
      comuna: "LAGO RANCO",
      region: "Los Ríos",
      name: "Liceo Antonio Varas",
      addres: "Calle Santiago 320",
      image: PlaceholderImage,
      lat: -40.322299,
      lng: -72.480415,
    },
    {
      comuna: "LAGO RANCO",
      region: "Los Ríos",
      name: "Liceo Ignao",
      addres: "Volcan Mirador S/N Sector Ignao",
      image: PlaceholderImage,
      lat: -40.332383,
      lng: -72.555353,
    },
    {
      comuna: "LAGO RANCO",
      region: "Los Ríos",
      name: "Los Rosales",
      addres: "Los Arrayanes S/N",
      image: PlaceholderImage,
      lat: -40.322223,
      lng: -72.469016,
    },
    {
      comuna: "PAILLACO",
      region: "Los Ríos",
      name: "Municipalidad",
      addres: "Vicuña Mackenna #340 ",
      image: PlaceholderImage,
      lat: -40.06976,
      lng: -72.873,
    },
    {
      comuna: "LAGO RANCO",
      region: "Los Ríos",
      name: "Pitruco",
      addres: "Ruta T-761 S/N Cruce Pitriuco",
      image: PlaceholderImage,
      lat: -40.27894,
      lng: -72.622374,
    },
    {
      comuna: "LAGO RANCO",
      region: "Los Ríos",
      name: "Pocura",
      addres: "Sector Cancha Pocura",
      image: PlaceholderImage,
      lat: -40.319944,
      lng: -72.475028,
    },
    {
      comuna: "LAGO RANCO",
      region: "Los Ríos",
      name: "Quillin",
      addres: "Ruta T.-775 S/N",
      image: PlaceholderImage,
      lat: -40.284774,
      lng: -72.552943,
    },
    {
      comuna: "LAGO RANCO",
      region: "Los Ríos",
      name: "Riñinahue",
      addres: "Ruta T-851 S/N (Centro Comunitario)",
      image: PlaceholderImage,
      lat: -40.322201,
      lng: -72.213778,
    },
    {
      comuna: "LAGO RANCO",
      region: "Los Ríos",
      name: "Rupumeica Bajo",
      addres: "Camino a Rupumeica Bajo S/N",
      image: PlaceholderImage,
      lat: -40.317259,
      lng: -71.956475,
    },
    {
      comuna: "LAGO RANCO",
      region: "Los Ríos",
      name: "Tringlo B",
      addres: "Callejon Rehel S/N",
      image: PlaceholderImage,
      lat: -40.326716,
      lng: -72.498933,
    },
    {
      comuna: "VALDIVIA ",
      region: "Los Ríos",
      name: "Plaza de la Republica",
      addres: "Plaza de la República, Valdivia",
      image: PlaceholderImage,
      lat: -39.81427554916317,
      lng: -73.24595601779112,
    },
    {
      comuna: "CHILOE",
      region: "Los Lagos",
      name: "PL SODIMAC CASTRO",
      addres: "Ruta 5 norte N° 2456, sector Tentén Castro",
      image: PlaceholderImage,
      lat: -42.45736990692667,
      lng: -73.7724293306442,
    },
    {
      comuna: "PUERTO VARAS",
      region: "Los Lagos",
      name: "WALMART PL PUERTO VARAS",
      addres: "Gramado 565, Puerto Varas",
      image: PlaceholderImage,
      lat: -41.317037,
      lng: -72.987891,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Instituto Teletón Puerto Montt",
      addres: "Egaña 650",
      image: PlaceholderImage,
      lat: -41.47012746055913,
      lng: -72.92461585397412,
    },
    {
      comuna: "Quemchi",
      region: "Los Lagos",
      name: "Centro de reciclaje Municipal",
      addres: "Poblacion Padre Hurtado, Quemchi",
      image: PlaceholderImage,
      lat: -42.14131325706929,
      lng: -73.4726608729795,
    },
    {
      comuna: "Fresia",
      region: "Los Lagos",
      name: "Colegio Purísimo Corazón de María",
      addres: "Irarrazabal 420",
      image: PlaceholderImage,
      lat: -41.153861,
      lng: -73.423005,
    },
    {
      comuna: "Río Negro",
      region: "Los Lagos",
      name: "Colegio Riachuelo",
      addres: "Manuel Balmaceda 490, Río Negro",
      image: PlaceholderImage,
      lat: -40.82214580132403,
      lng: -73.33549157672043,
    },
    {
      comuna: "Calbuco",
      region: "Los Lagos",
      name: "Circulo Fraterno",
      addres: "Circulo Fraterno",
      image: PlaceholderImage,
      lat: -41.755434151361534,
      lng: -73.15211541602591,
    },
    {
      comuna: "Calbuco",
      region: "Los Lagos",
      name: "LFHOP",
      addres: "Pedro Felix Oyarzún 493",
      image: PlaceholderImage,
      lat: -41.76198816038887,
      lng: -73.15661587548418,
    },
    {
      comuna: "Calbuco",
      region: "Los Lagos",
      name: "Escuela Balmaceda",
      addres: "Presidente Ibañez 151",
      image: PlaceholderImage,
      lat: -41.773025136531324,
      lng: -73.13371473186537,
    },
    {
      comuna: "Calbuco",
      region: "Los Lagos",
      name: "Escuela Bernardo Ohiggnis",
      addres: "Av Cacicaen (Escuela Bernardo Ohiggnis)",
      image: PlaceholderImage,
      lat: -41.78213757877001,
      lng: -73.1372428923759,
    },
    {
      comuna: "Río Negro",
      region: "Los Lagos",
      name: "Colegio Sagrada Familia",
      addres: "Arturo Prat 855",
      image: PlaceholderImage,
      lat: -40.803884,
      lng: -73.216205,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 1",
      addres: "Avenida Cuarta Terraza, Valle Volcanes",
      image: PlaceholderImage,
      lat: -41.461284,
      lng: -72.9134655,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 10",
      addres: "Sierra Nevada, Mirasol",
      image: PlaceholderImage,
      lat: -41.4805506,
      lng: -72.9644608,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 11",
      addres: "Avenida Los Alerces, Alerce",
      image: PlaceholderImage,
      lat: -41.3988787,
      lng: -72.9168399,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 12",
      addres: "22 De mayo con calle Uruguay",
      image: PlaceholderImage,
      lat: -41.4692091,
      lng: -72.9548899,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 13",
      addres: "Calle San Diego con calle Cochrane",
      image: PlaceholderImage,
      lat: -41.4704455,
      lng: -72.9598758,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 14",
      addres: "Calle Balmaceda con calle alcalde",
      image: PlaceholderImage,
      lat: -41.4686006,
      lng: -72.9462717,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 15",
      addres: "Séptimo de Línea con Francisco Pizarro",
      image: PlaceholderImage,
      lat: -41.4578566,
      lng: -72.9434737,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 16",
      addres: "Calle Serrano pasaje Libertad 1",
      image: PlaceholderImage,
      lat: -41.4698737,
      lng: -72.9271027,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 17",
      addres: "Calle Los Maquis, Población Ebensperger",
      image: PlaceholderImage,
      lat: -41.4733642,
      lng: -72.9580185,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 18",
      addres: "Calle Lautaro, frente iglesia Colina",
      image: PlaceholderImage,
      lat: -41.4642219,
      lng: -72.9210819,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 19",
      addres: "Calle Llanquihue con Pedro Aguirre Cerda",
      image: PlaceholderImage,
      lat: -41.4651545,
      lng: -72.9176065,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 2",
      addres: "Avenida Michimavida, Valle Volcanes",
      image: PlaceholderImage,
      lat: -41.4565887,
      lng: -72.911785,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 20",
      addres: "Calle Bremen",
      image: PlaceholderImage,
      lat: -41.4660791,
      lng: -72.9159377,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 21",
      addres: "Calle Regimiento con Palena",
      image: PlaceholderImage,
      lat: -41.468449,
      lng: -72.9241419,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 22",
      addres: "Calle Lago Villarrica con Merino Benítez",
      image: PlaceholderImage,
      lat: -41.4735229,
      lng: -72.9117631,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 23",
      addres: "Calle Dalcahue con Lin Lin",
      image: PlaceholderImage,
      lat: -41.4612918,
      lng: -72.927287,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 24",
      addres: "Calle Icalma con Los Canarios",
      image: PlaceholderImage,
      lat: -41.4583692,
      lng: -72.9268274,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 25",
      addres: "Calle Lago Cucao",
      image: PlaceholderImage,
      lat: -41.4504787,
      lng: -72.9100381,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 26",
      addres: "Calle Galvarino Vera",
      image: PlaceholderImage,
      lat: -41.4617247,
      lng: -72.9172201,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 27",
      addres: "Calle Galvarino Vera",
      image: PlaceholderImage,
      lat: -41.4626773,
      lng: -72.9172551,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 28",
      addres: "Artemio Mancilla",
      image: PlaceholderImage,
      lat: -41.4609216,
      lng: -72.9201931,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 29",
      addres: "Maximiliano Uribe",
      image: PlaceholderImage,
      lat: -41.4768,
      lng: -72.98533,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 3",
      addres: "Avenida Michimavida, Valle Volcanes",
      image: PlaceholderImage,
      lat: -41.461936,
      lng: -72.9127761,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 30",
      addres: "Pasaje Rebolledo",
      image: PlaceholderImage,
      lat: -41.4629767,
      lng: -72.922631,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 31",
      addres: "Calle Constantino Cochifas",
      image: PlaceholderImage,
      lat: -41.5274648,
      lng: -73.0676688,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 32",
      addres: "Calle Isla Caullahuapi",
      image: PlaceholderImage,
      lat: -41.5262199,
      lng: -73.0672718,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 33",
      addres: "Sargento Silva",
      image: PlaceholderImage,
      lat: -41.45305,
      lng: -72.93665,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 34",
      addres: "Archipiélago Juan Fernández con Capitán Avalos",
      image: PlaceholderImage,
      lat: -41.471123,
      lng: -73.21674,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 35",
      addres: "Pasaje Maestranza Cernoch",
      image: PlaceholderImage,
      lat: -41.453378,
      lng: -72.957445,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 36",
      addres: "Malvina Lazo con Puerto Natales",
      image: PlaceholderImage,
      lat: -41.478547,
      lng: -72.98367,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 37",
      addres: "Calle Bosque Amarillo, Población Bosque Mar",
      image: PlaceholderImage,
      lat: -41.485652,
      lng: -73.002266,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 38",
      addres: "Calle Laguna de Huasco con pasaje Lago Maihue",
      image: PlaceholderImage,
      lat: -41.457506,
      lng: -72.899003,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 39",
      addres: "Calle Lord Cochrane",
      image: PlaceholderImage,
      lat: -41.465817,
      lng: -72.963467,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 4",
      addres: "Cerro Tronador, Valle Volcanes",
      image: PlaceholderImage,
      lat: -41.4588767,
      lng: -72.9004225,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 40",
      addres: "Calle Chinquihue 2 con pasaje Mark Chagall",
      image: PlaceholderImage,
      lat: -41.50845,
      lng: -73.013267,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 41",
      addres: "Volcán Corcovado 5800 (condominio los volcanes)",
      image: PlaceholderImage,
      lat: -41.456814,
      lng: -72.903625,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 42",
      addres: "Volcán Choshuenco",
      image: PlaceholderImage,
      lat: -41.454323,
      lng: -72.903625,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 43",
      addres: "Calle Los Leones con pje. Los Gatos",
      image: PlaceholderImage,
      lat: -41.501833,
      lng: -72.927132,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 44",
      addres: "Calle Andalucita con calle Biotita",
      image: PlaceholderImage,
      lat: -41.445332,
      lng: -72.964643,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 45",
      addres: "Calle Ana Gonzalez Olea con Mario Carreño Morales (Alerce)",
      image: PlaceholderImage,
      lat: -41.478543,
      lng: -72.934743,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 46",
      addres: "Calle Pedro Reska Moreau con Ana Gonzalez (Alerce)",
      image: PlaceholderImage,
      lat: -41.435242,
      lng: -73.524536,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 47",
      addres: "Calle Granítica",
      image: PlaceholderImage,
      lat: -41.4243,
      lng: -72.237765,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 5",
      addres: "Nueva Oriente 4, Valle Volcanes",
      image: PlaceholderImage,
      lat: -41.4568043,
      lng: -72.9034238,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 6",
      addres: "Volcán Lascar, Valle Volcanes",
      image: PlaceholderImage,
      lat: -41.4553092,
      lng: 72.9140634,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 7",
      addres: "Avenida Marcelo Fourcade, Valle Volcanes",
      image: PlaceholderImage,
      lat: -41.4578662,
      lng: -72.9075403,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 8",
      addres: "Los Colonos, La Vara",
      image: PlaceholderImage,
      lat: -41.4224255,
      lng: -72.9092186,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contendor 9",
      addres: "Los Eucaliptos, La Colina",
      image: PlaceholderImage,
      lat: -41.4649315,
      lng: -72.9255774,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contenedor Rural 1",
      addres: "Caleta La Arena",
      image: PlaceholderImage,
      lat: -41.692487,
      lng: -72.640102,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contenedor Rural 2",
      addres: "Pieda Azul",
      image: PlaceholderImage,
      lat: -41.508428,
      lng: -72.790172,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contenedor Rural 3",
      addres: "Lago Chapo",
      image: PlaceholderImage,
      lat: -41.432982,
      lng: -72.57702,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contenedor Rural 4",
      addres: "Lago Chapo",
      image: PlaceholderImage,
      lat: -41.432982,
      lng: -72.57702,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contenedor Rural 5",
      addres: "Lago Chapo",
      image: PlaceholderImage,
      lat: -41.432507,
      lng: -72.575115,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contenedor Rural 6",
      addres: "Rio Blanco",
      image: PlaceholderImage,
      lat: -41.404637,
      lng: -72.626905,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Contenedor Rural 7",
      addres: "Ilque",
      image: PlaceholderImage,
      lat: -41.622463,
      lng: -73.086005,
    },
    {
      comuna: "Fresia",
      region: "Los Lagos",
      name: "Delegación Parga",
      addres: "Romulo Rodriguez S/N",
      image: PlaceholderImage,
      lat: -41.218709,
      lng: -73.486802,
    },
    {
      comuna: "Fresia",
      region: "Los Lagos",
      name: "Delegación Tegualda",
      addres: "Calle America S/N",
      image: PlaceholderImage,
      lat: -41.040453,
      lng: -73.410994,
    },
    {
      comuna: "Río Negro",
      region: "Los Lagos",
      name: "Esc. De lenguaje Dora",
      addres: "Balmaceda 223, Río Negro",
      image: PlaceholderImage,
      lat: -40.79614603962528,
      lng: -73.21196788086668,
    },
    {
      comuna: "Río Negro",
      region: "Los Lagos",
      name: "Escuela Andrew Jackson",
      addres: "Mac-Iver 143, Río Negro",
      image: PlaceholderImage,
      lat: -40.79135573575616,
      lng: -73.21720609368703,
    },
    {
      comuna: "Río Negro",
      region: "Los Lagos",
      name: "Escuela Ramon Puschel",
      addres: "Chan Chan s/n",
      image: PlaceholderImage,
      lat: -40.813389,
      lng: -72.989444,
    },
    {
      comuna: "Río Negro",
      region: "Los Lagos",
      name: "Escuela Rio Negro",
      addres: "Pedro Montt 880, Río Negro",
      image: PlaceholderImage,
      lat: -40.80412685519945,
      lng: -73.21407092378747,
    },
    {
      comuna: "Futaleufú",
      region: "Los Lagos",
      name: "Frontis municipalidad",
      addres: "Pedro Aguirre Cerda 271.",
      image: PlaceholderImage,
      lat: -43.185084565744255,
      lng: -71.86724689104491,
    },
    {
      comuna: "Río Negro",
      region: "Los Lagos",
      name: "HEPI Crianza",
      addres: "Enrique Mac-Iver, Río Negro (teminal buses)",
      image: PlaceholderImage,
      lat: -40.792928925882585,
      lng: -73.21606391098126,
    },
    {
      comuna: "Río Negro",
      region: "Los Lagos",
      name: "Jardín Infantil Amancay",
      addres: "Valparaiso S/N, 5390100, Río Negro",
      image: PlaceholderImage,
      lat: -40.78796128547637,
      lng: -73.21580599227717,
    },
    {
      comuna: "Río Negro",
      region: "Los Lagos",
      name: "Jardin infantil Espiguita",
      addres: "Lago Puyehue s/n",
      image: PlaceholderImage,
      lat: -40.80687905407216,
      lng: -73.21893916669396,
    },
    {
      comuna: "Río Negro",
      region: "Los Lagos",
      name: "Junta de vecinos Buenaventura",
      addres: "Buenaventura s/n",
      image: PlaceholderImage,
      lat: -40696602,
      lng: -73.119997,
    },
    {
      comuna: "Fresia",
      region: "Los Lagos",
      name: "Liceo Carlos Ibañez del Campo",
      addres: "O'Higgins 210",
      image: PlaceholderImage,
      lat: -41.153192,
      lng: -73.417031,
    },
    {
      comuna: "Río Negro",
      region: "Los Lagos",
      name: "Liceo JTM",
      addres: "Av. Padre Alberto Hurtado 21, Río Negro",
      image: PlaceholderImage,
      lat: -40.789944305007985,
      lng: -73.21389808858203,
    },
    {
      comuna: "Calbuco",
      region: "Los Lagos",
      name: "Municipalidad de Calbuco",
      addres: "Federico Errazuriz, 210 Calbuco",
      image: PlaceholderImage,
      lat: -41.77254342492038,
      lng: -73.12726965180244,
    },
    {
      comuna: "Fresia",
      region: "Los Lagos",
      name: "Municipalidad de Fresia",
      addres: "San Francisco 124",
      image: PlaceholderImage,
      lat: -41.153049,
      lng: -73.421526,
    },
    {
      comuna: "Futaleufú",
      region: "Los Lagos",
      name: "Plaza de armas",
      addres: "Bernardo O'Higgins s/n",
      image: PlaceholderImage,
      lat: -43.186146,
      lng: -71.866764,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Punto Limpio",
      addres: "Sargento Silva con Estero Lobos s/n",
      image: PlaceholderImage,
      lat: -41.459307,
      lng: -72.938774,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Punto Limpio",
      addres: "Enrique Gomez Correa 1735 Pob. Padre Hurtado",
      image: PlaceholderImage,
      lat: -41.474928,
      lng: -72.981437,
    },
    {
      comuna: "Cochamó",
      region: "Los Lagos",
      name: "Punto limpio Cochamó",
      addres: "Teniente Merino S/N, (a pasos de posta cochamo)",
      image: PlaceholderImage,
      lat: -41.49397,
      lng: -72.306823,
    },
    {
      comuna: "Cochamó",
      region: "Los Lagos",
      name: "punto limpio Llanada grande",
      addres: "Llanada Grande S/N (frente a oficina municipal)",
      image: PlaceholderImage,
      lat: -41.870354,
      lng: -71.941595,
    },
    {
      comuna: "Puerto Varas",
      region: "Los Lagos",
      name: "Punto Limpio Municipal Gimnasio Fiscal",
      addres: "San Francisco #965 (detrás del Gimnasio Fiscal)",
      image: PlaceholderImage,
      lat: -41.321704,
      lng: -72.984401,
    },
    {
      comuna: "Cochamó",
      region: "Los Lagos",
      name: "Punto limpio Puelo",
      addres: "Volcan Yates S/N, (al costado casa del turista)",
      image: PlaceholderImage,
      lat: -41.664544,
      lng: -72.296125,
    },
    {
      comuna: "Puerto Montt",
      region: "Los Lagos",
      name: "Punto Móvil",
      addres: "Punto Móvil",
      image: PlaceholderImage,
      lat: -41.459723112117004,
      lng: -72.9490404595655,
    },
    {
      comuna: "Puyehue",
      region: "Los Lagos",
      name: "Punto Verde Municipal",
      addres: "Manuel Rodríguez con Bernardo O´Higgins",
      image: PlaceholderImage,
      lat: -40.683274393620046,
      lng: -72.59897333295099,
    },
    {
      comuna: "Coyhaique",
      region: "Aysén",
      name: "Instituto Teletón Aysén",
      addres: "Calle Ejército 567, Coyhaique",
      image: PlaceholderImage,
      lat: -45.56474246940602,
      lng: -72.07476863068393,
    },
    {
      comuna: "Lago Verde",
      region: "Aysén",
      name: "delegacion amengual",
      addres: "francisco castro",
      image: PlaceholderImage,
      lat: -44.74764049416797,
      lng: -72.2105565037983,
    },
    {
      comuna: "Rio Ibañez",
      region: "Aysén",
      name: "Delegación Municipal Puerto Río Tranquilo",
      addres: "Dagoberto Godoy N°161, Puerto Río Tranquilo",
      image: PlaceholderImage,
      lat: -46.62485520221642,
      lng: -72.67526041522693,
    },
    {
      comuna: "Rio Ibañez",
      region: "Aysén",
      name: "Delegación Municipal Villa Cerro Castillo",
      addres:
        "Av. Bernardo O'Higgins S/N (Frente a Biblioteca Villa Cerro Castillo)",
      image: PlaceholderImage,
      lat: -46.12240437999586,
      lng: -72.15512113640311,
    },
    {
      comuna: "Lago Verde",
      region: "Aysén",
      name: "delegacion tapera",
      addres: "18 de septiembre",
      image: PlaceholderImage,
      lat: -44.64727891601525,
      lng: -71.6725627348987,
    },
    {
      comuna: "Lago Verde",
      region: "Aysén",
      name: "Escuela lago verde",
      addres: "cacique blanco 131",
      image: PlaceholderImage,
      lat: -44.240098879717756,
      lng: -71.8480478589369,
    },
    {
      comuna: "Cochrane",
      region: "Aysén",
      name: "Estacionamiento Gimnasio ",
      addres: "Gimnasio Municipal de Cochrane",
      image: PlaceholderImage,
      lat: -47.25199962736081,
      lng: -72.57331471910065,
    },
    {
      comuna: "Rio Ibañez",
      region: "Aysén",
      name: "Ilustre Municipalidad de Río Ibáñez",
      addres:
        "Carlos Soza N° 161, Puerto Ingeniero Ibáñez (Detrás de la Radio Padre Antonio Ronchi)",
      image: PlaceholderImage,
      lat: -46.29090703587126,
      lng: -71.93701283538682,
    },
    {
      comuna: "Coyhaique",
      region: "Aysén",
      name: "Paseo Peatonal Horn",
      addres: "Horn con Plaza - Paseo Peatonal",
      image: PlaceholderImage,
      lat: -45.57165025363118,
      lng: -72.06973437688116,
    },
    {
      comuna: "AYSEN",
      region: "Aysén",
      name: "Jose Manuel Balmaceda - Coyhaique",
      addres: "Km 7, José Manuel Balmaceda",
      image: PlaceholderImage,
      lat: -45.625362,
      lng: -72.099226,
    },
    {
      comuna: "PTA ARENAS",
      region: "Magallanes",
      name: "Zona Franca Punta Arenas",
      addres: "Avenida 2 sur #230 Punta Arenas. Recinto zona franca",
      image: PlaceholderImage,
      lat: -53.13291714797162,
      lng: -70.87718687138637,
    },
  ]);

  const [selectedLocation, setSelectedLocation] = useState(null);

  const parseCoordinates = (coordinates) => {
    if (typeof coordinates === "string") {
      const [lat, lng] = coordinates
        .replace("°", "")
        .replace("S", "")
        .replace("W", "")
        .split(/\D+/)
        .filter((part) => part !== "")
        .map(parseFloat);

      return { lat, lng };
    }

    return { lat: coordinates, lng: coordinates };
  };

  const handleLocationSelect = (location) => {
    const { lat, lng, coordinates } = location;

    let selectedCoordinates;

    if (coordinates) {
      selectedCoordinates = parseCoordinates(coordinates);
    } else if (lat !== null && lng !== null) {
      selectedCoordinates = { lat, lng };
    } else {
      console.error("Coordenadas no válidas.");
      return;
    }

    if (document.querySelector(".content-search-location.open")) {
      document
        .querySelector(".content-search-location.open")
        .classList.remove("open");
    }

    // Agrega la clase "active" al elemento de la lista que se ha hecho clic
    const listItems = document.querySelectorAll(".location-item");
    listItems.forEach((item) => {
      item.classList.remove("active");
    });

    const clickedListItem = document.getElementById(location.name);
    clickedListItem.classList.add("active");

    mapRef.current.map.panTo(selectedCoordinates);
    mapRef.current.map.setZoom(15);

    setSelectedLocation(selectedCoordinates);
  };

  const filteredData = locations.filter((item) => {
    const comuna = item.comuna ? item.comuna.toLowerCase() : "";
    const name = item.name ? item.name.toLowerCase() : "";
    const addres = item.addres ? item.addres.toLowerCase() : "";
    const region = item.region ? item.region.toLowerCase() : "";

    return (
      comuna.includes(comunaFilter.toLowerCase()) ||
      name.includes(comunaFilter.toLowerCase()) ||
      addres.includes(comunaFilter.toLowerCase()) ||
      region.includes(comunaFilter.toLowerCase())
    );
  });

  return (
    <div className="container-location">
      <div
        className="content-search-location"
        onClick={(e) => e.target.classList.add("open")}
      >
        <img className="chevronTop" src={Arrow} alt="" />
        <label>Buscar un punto limpio</label>
        <img className="chevronBot" src={Arrow} alt="" />
        <input
          type="text"
          placeholder="Buscar un punto limpio"
          value={comunaFilter}
          onChange={(e) => setComunaFilter(e.target.value)}
        />

        <ul>
          {filteredData.map((location, index) => (
            <li
              className="location-item"
              key={index}
              id={location.name}
              onClick={() => handleLocationSelect(location)}
            >
              <div className="wrapper-img">
                <img src={location.image} alt="" />
              </div>
              <div className="wrapper-text">
                <h4>{location.name}</h4>
                <h5>{location.addres}</h5>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <Map
        className="googleMap"
        google={google}
        zoom={13}
        initialCenter={{ lat: -33.45614673683549, lng: -70.69897578465805 }}
        ref={mapRef}
      >
        {selectedLocation && (
          <Marker
            position={{
              lat: selectedLocation.lat,
              lng: selectedLocation.lng,
            }}
            icon={{
              url: ImagePin, // Reemplaza con la URL de tu imagen
              scaledSize: new window.google.maps.Size(40, 50), // Tamaño de la imagen
            }}
            title={selectedLocation.name}
          />
        )}
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyCYIHTek0d-vXQCSLupAeR29TaZaC4o-tk",
})(MapContainer);
