import React, { useEffect, useState, useRef } from 'react'
import { toPng } from 'html-to-image';

import TxtHeader from '../../assets/cumpleanios/texto-header.png'
import GlobosDesktopHeader from '../../assets/cumpleanios/globoDesktopHeader.png'
import GlobosMobileHeader from '../../assets/cumpleanios/globosMobileHeader.png'
import GlobosMobile from '../../assets/cumpleanios/globosMobile.png'
import GlobosMobile2 from '../../assets/cumpleanios/globosMobile2.png'
import Girnaldas from '../../assets/cumpleanios/girnaldas.png'
import GirnaldasDesktop from '../../assets/cumpleanios/girnaldasDesktop.png'
import BorderBody from '../../assets/cumpleanios/Forma2.png'
import LogoMampato from '../../assets/cumpleanios/logo-mampato.png'
import LogoCinePlanet from '../../assets/cumpleanios/logo-cinePlanet.png'
import LogoFantasilandia from '../../assets/cumpleanios/logo-fantasilandia.png'
import LogoByP from '../../assets/img/logobyp.png'
import LogoKidzania from '../../assets/cumpleanios/logo-kidzania.png'

import BasesConcurso from '../../assets/cumpleanios/Bases-ConcursosCumpleanioos.pdf'

import TextCumple from '../../assets/cumpleanios/text-cumple.png'

import ImageCheer from '../../assets/cumpleanios/saludo-byp.png'

import BottleYellow from '../../assets/cumpleanios/BOTELLA_PAP.png'
import BottleRed from '../../assets/cumpleanios/BOTELLA_BILZ.png'

import MarcianoBilz from '../../assets/cumpleanios/marcianoBilz.png'
import MarcianoPap from '../../assets/cumpleanios/marcianoPap.png'

import './index.scss';

export const HappyBirthday = ({ hideHeader, handle }) => {
  const elementRef = useRef(null);
  const formRef = useRef(null)
  const [formData, setFormData] = useState({})
  const [sendingData, setSendingData] = useState(false)
  const [showModalCheer, setShowModalCheer] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()

    setSendingData(true)

    await fetch("https://hooks.zapier.com/hooks/catch/2792106/3tazunf/", {
      method: "POST",
      body: JSON.stringify(formData),
    })
      .then(async (response) => {
        e.target.reset()
        // console.log(formData)
      })
      .catch((err) => {
        // console.log("Algo sucedió");
      });
  }

  const htmlToImageConvert = () => {
    toPng(elementRef.current, { cacheBust: false })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "my-cheer.png";
        link.href = dataUrl;
        link.click();

        setShowModalCheer(false)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadCheer = (e) => {
    e.preventDefault()

    if (formData.nombreNinio === undefined) {
      console.log('Debes completar el formulario')
      formRef.current[0].focus()
    } else {
      console.log('Descargando imagen de', formData.nombreNinio);

      setShowModalCheer(true)
      setSendingData(false)
    }
  }

  useEffect(() => {
    handle(false);
    return () => handle(true);
  }, [handle]);

  useEffect(() => {
    hideHeader(false);
    return () => hideHeader(true);
  }, [hideHeader]);

  return (
    <section className="content-happybirthday">
      <div className="container-happybirthday">
        <div className="wrapper-header">
          <img src={Girnaldas} alt="" className="girnaldas mobile" />
          <img src={GirnaldasDesktop} alt="" className="girnaldas desktop" />
          <img src={GlobosDesktopHeader} alt="" className="globos desktop" />
          <img src={GlobosMobileHeader} alt="" className="globos mobile" />
          <div className="content-images">
            <img src={TxtHeader} alt="" className="txtHeader" />
            <img src={BottleYellow} className="imgYellow" alt="" />
            <img src={BottleRed} className="imgRed" alt="" />
          </div>

          <img src={BorderBody} className="imgBottom" alt="" />
        </div>

        <div className="wrapper-body">
          <img src={GlobosMobile2} alt="" className="globos desktop" />
          <img src={GlobosMobile} alt="" className="body globos mobile" />
          <div className="overlay"></div>

          <div className="content-text">
            <img src={TextCumple} alt="" />

            <p>
              Inscríbete y participa por entradas para ti y tus amigos, para
              celebrar tu cumpleaños en
            </p>
            <div className="wrapper-brands">
              <img src={LogoMampato} alt="" />
              <img src={LogoCinePlanet} alt="" />
              {/* <img src={LogoFantasilandia} alt="" /> */}
              {/* <img src={LogoKidzania} alt="" /> */}
            </div>
          </div>

          <img src={MarcianoBilz} alt="" className="marcianoBilz" />
          <img src={MarcianoPap} alt="" className="marcianoPap" />

          <div className="content-form">
            <h4>Formulario:</h4>
            <form onSubmit={handleSubmit} ref={formRef}>
              <input
                type="text"
                placeholder="NOMBRE TUTOR"
                onChange={(e) =>
                  setFormData({ ...formData, nombreTutor: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="TELÉFONO"
                onChange={(e) =>
                  setFormData({ ...formData, telefono: e.target.value })
                }
              />
              <input
                type="email"
                placeholder="E-MAIL TUTOR"
                onChange={(e) =>
                  setFormData({ ...formData, emailTutor: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="DIRECCIÓN"
                onChange={(e) =>
                  setFormData({ ...formData, direccion: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="NOMBRE NIÑO/A"
                onChange={(e) =>
                  setFormData({ ...formData, nombreNinio: e.target.value })
                }
              />
              <input
                placeholder="Fecha de cumpleaños"
                type="date"
                onChange={(e) =>
                  setFormData({ ...formData, diaCumpleanios: e.target.value })
                }
              />
              <button type="submit" className={sendingData ? "disabled" : ""}>
                Enviar
              </button>

              <a href={BasesConcurso} target="_blank" rel="noopener noreferrer">
                Ver bases legales
              </a>
            </form>
          </div>

          <div className="content-footer">
            <a
              href="/"
              className={sendingData ? "" : "disabled"}
              onClick={(e) => downloadCheer(e)}
            >
              Descarga un saludo!
            </a>

            <img src={LogoByP} alt="" />
          </div>
        </div>

        {showModalCheer ? (
          <div className="content-modal">
            <div className="container-floatCheer" ref={elementRef}>
              <h3 className="name">{formData.nombreNinio}!</h3>
              <img src={ImageCheer} alt="" />
            </div>

            <div className="content-controls">
              <button onClick={(e) => setShowModalCheer(false)}>Cerrar</button>
              <button onClick={(e) => htmlToImageConvert(e)}>Descargar</button>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </section>
  );
}
