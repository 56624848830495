import React, { useState } from "react";
import { HomeSlider } from "../../DumbComponents/HomeSlider/HomeSlider";
// import { Modal } from "../../SmartComponents/Modal/Modal";
// import popup from "../../assets/img/popup_nabilzpap_23_media.jpg";
// import nabilzpapvideo from "../../assets/nabilzpap.mp4";
//import { FloatingButton } from "../../DumbComponents/FloatingButton/FloatingButton";
import "./home.scss";

export const Home = () => {
  // El modal está abierto por defecto
  // const [isModalOpen, setIsModalOpen] = useState(true);

  // const closeModal = () => setIsModalOpen(false);

  return (
    <div className="home">
      <div className="home__text">
        <img
          src="https://user-images.githubusercontent.com/55818246/130894829-a4ad546e-7c1c-496f-a067-b6e33317a4d8.png"
          alt="¡Comienza un viaje de otro mundo!"
        />
      </div>
      <HomeSlider />

      {/* <Modal isOpen={isModalOpen} closeModal={closeModal}>
        <video width="auto" height="auto" controls>
        <source src={nabilzpapvideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      </Modal> */}
    </div>
  );
};
